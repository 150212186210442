.funnel-page {
  background: $ff-grey-active;
  .steps {
    padding-left: 0;
    padding-right: 0;
    margin-left: 12px;
    border-left: 2px solid $ff-active-blue;
    .panel-group {
      .panel {
        border-radius: 0 !important;
        .panel-heading {
          background: rgba(255, 255, 255, 0.9) !important;
          border-radius: 0 !important;
          height: 25px;
          cursor: pointer;
          border: 0 none !important;
          padding: 0;
          h4 {
            font-size: 12px;
            padding: 5px 40px;
            a {
              text-decoration: none;
            }
          }
          .step-number {
            height: 26px;
            width: 26px;
            font-size: 12px;
            background: $ff-active-blue;
            color: $white;
            position: absolute;
            padding-top: 4px;
            left: -14px;
            top: 0;
            &.index {
              top: 30px;
              left: -30px;
              padding-top: 12px;
              height: 60px;
              width: 60px;
              span {
                font-size: 20px;
                font-weight: bold;
              }
            }
          }
        }
        .panel-body {
          margin-top: -25px;
          background: $white !important;
          border-top: 0 none !important;
          h2 {
            margin: 35px 0 15px;
            font-weight: bold;
            font-size: 20px;
          }
          p {
            margin: 0;
            font-size: 14px;
            .pointer {
              span {
                color: $ff-active-blue;
              }
            }
          }
          .funnel-images {
            height: 112px;
            width: 112px;
          }
          ul {
            margin-top: 50px;
            margin-bottom: 30px;
            li {
              margin-right: 60px;
              vertical-align: top;
              font-size: 12px;
              img {
                cursor: pointer;
              }
              p {
                font-size: 12px;
                margin-top: 15px !important;
              }
              .checkbox {
                padding: 0;
              }
              .other {
                padding-left: 6px;
                input {
                  @include placeholder($ff-grey-active);
                }
              }
            }
            span {
              font-size: 12px;
            }
            &.tip-list {
              li {
                &:first-child {
                  margin-top: 75px;
                }
                &:last-child {
                  margin-top: 35px;
                }
                margin-top: 25px;
                font-size: 12px;
              }
            }
          }
          .what-is-pp {
            p {
              font-size: 12px !important;
              .caret {
                cursor: pointer;
              }
              &:first-child {
                margin-bottom: 12px;
              }
            }
          }
          .address-form {
            margin-top: 30px;
            label {
              font-size: 10px !important;
            }
            button {
              margin-top: 40px !important;
              margin-bottom: 33px !important;
              &:first-child {
                margin-right: 10px;
              }
              &:last-child {
                color: $ff-active-blue !important;
                border-color: $ff-active-blue !important;
                padding: 6px 12px !important;
                &:active, &:focus {
                  color: $white !important;
                }
              }
            }
          }
          textarea {
            margin-top: 30px;
            margin-bottom: 55px;
            resize: none;
          }
          small {
            font-size: 8px;
          }
          button {
            &.submit-btn {
              margin-top: 15px;
              padding: 6px 30px;
              font-size: 12px;
              color: $header-menu-item-color;
              border: 1px solid $header-menu-item-color;
              &:hover {
                background-color: transparent !important;
              }
              &.andere {
                border-radius: 0;
                display: block;
                position: relative;
                top: 5px;
              }
              &:focus, &:active {
                background: $ff-active-blue !important;
                border-color: $ff-active-blue;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  .funnel-panels {
    padding-top: 50px;
    div:last-child {
      border-left: 0 none;
    }
  }
}

.how-photos {
  position: relative;
  .order-input {
    width: 74px;
    z-index: 100;
    position: absolute;
    top: -5px;
    left: 28px;
    padding: 6px;
  }
}

.funnel-tel {
  .btn-group {
    span {
      font-size: 12px;
    }
  }
}

.add-img {
  margin-top: 25px;
  h4 {
    font-size: 12px;
    color: $header-menu-item-color;
  }
  .chosen-img {
    height: 50px;
    width: 50px;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 15px;
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
    .close {
      color: $black;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 5px;
      text-shadow: none !important;
      font-size: 12px;
      opacity: 1 !important;
    }
  }
  .choose-file {
    padding-left: 0;
    span {
      &:first-child {
        background: transparent !important;
        color: $ff-active-blue;
        text-decoration: underline;
        border: 0 none !important;
        padding-left: 0;
      }
    }
  }
}

.well {
  width: 290px !important;
  background-color: transparent !important;
  border: 0 none !important;
  box-shadow: none !important;
  &:focus {
    border: 0 none !important;
    box-shadow: none !important;
    outline: none !important;
  }
  table {
    &:focus {
      outline: none !important;
      border: 0 none !important;
    }
    thead {
      tr {
        &:nth-child(2) {
          th {
            &:first-child {
              display: none !important;
            }
          }
        }
        th {
          &:nth-child(2) {
            button {
              width: 170px !important;
            }
          }
          &:nth-child(3) {
            button {
              margin-left: -40px;
            }
          }
          button {
            border: 0 none !important;
            border-radius: 0 !important;
            background-color: transparent !important;
            color: $calendar-color;
            &:hover, &:focus, &:active {
              border: 0 none !important;
              border-radius: 0 !important;
              background-color: transparent !important;
              color: $calendar-color;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            display: none !important;
          }
          button {
            border: 0 none !important;
            background-color: transparent !important;
            border-radius: 0 !important;
            &:hover {
              background-color: transparent !important;
              color: $ff-grey-active;
            }
            &:active, &:focus, &.active {
              background-color: $ff-active-blue !important;
              color: $white !important;
              box-shadow: none !important;
            }
          }
        }
      }
    }
  }
}

.order-done-container {
  margin-top: -40px;
  color: $white;
  width: 300px;
  @include centerer();
  button {
    width: auto !important;
    padding: 6px 12px !important;
  }
}

.video-url-sample {
  color: $ff-active-blue;
  font-size: 12px;
  margin-top: 15px;
  margin-left: 45px;
}

#selection {
  .modal-dialog {
    width: 960px;
    .modal-content {
      .modal-body {
        height: 630px;
        padding-top: 0;
        padding-bottom: 0;
        .row, .row > div, .map-wrapper {
          height: 100%;
        }
        .map-wrapper {
          padding-left: 0;
          padding-right: 0;
        }
        form {
          .stylish-input-group {
            background: $white !important;
            width: 100%;
            border-radius: 0;
            .form-control {
              display: block;
              width: 100%;
              height: 34px;
              padding: 6px 12px;
              font-size: 14px;
              line-height: 1.42857143;
              color: #555555;
              background-color: #fff;
              background-image: none;
              border: 1px solid #ccc;
              border-radius: 4px;
              box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
              transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
            }
          }
        }
        .select-area {
          position: absolute;
          display: flex;
          align-items: center;
          top: 60px;
          left: 70px;
          width: 600px;
          height: 65px;
          background-color: $map-selection-bg-color;
          img.selected-area {
            margin-top: 12px;
          }
          span {
            color: $white;
            font-size: 14px;
            img {
              height: 15px;
              width: 15px;
              margin-top: 10px;
            }
          }
        }
        .map-selection {
          background-color: $map-selection-bg-color;
          h4 {
            font-family: 'Arquitecta';
            font-size: 18px;
            color: $white;
            font-weight: bold;
            margin-top: 62px;
          }
          p {
            font-size: 12px;
            line-height: 20px;
            color: $white;
            &.selected-address {
              position: absolute;
            }
          }
          a {
            color: $ff-active-blue;
            text-decoration: underline;
          }
          form {
            height: 120px;
          }
          .reset-selection, .confirm-selection {
            border-color: $ff-active-blue;
            background-color: $transparent;
            color: $ff-active-blue;
            font-size: 14px;
          }
          .reset-selection {
            margin-top: 20px;
          }
          .confirm-selection {
            margin-top: 125px;
          }
          .confirm-selection-active {
            background-color: $ff-dark-blue;
            color: $white;
          }

        }
      }
    }
  }
}
