@font-face {
  font-family: 'Arquitecta';
  src: url('/assets/fonts/arquitecta.eot');
  src: url('/assets/fonts/arquitecta.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/arquitecta.woff') format('woff'),
    url('/assets/fonts/arquitecta.ttf') format('truetype'),
    url('/assets/fonts/arquitecta.svg#Arquitecta') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lucida-Grande';
  src: url('/assets/fonts/lucida-grande.eot');
  src: url('/assets/fonts/lucida-grande.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/lucida-grande.woff') format('woff'),
    url('/assets/fonts/lucida-grande.ttf') format('truetype'),
    url('/assets/fonts/lucida-grande.svg#Lucida-Grande') format('svg');
  font-weight: normal;
  font-style: normal;
}
