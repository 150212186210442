@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  @include translate(-50%, -50%);
}

@mixin keyframes( $bannermove ) {
  @-webkit-keyframes bannermove {
    @content;
  }
  @-moz-keyframes bannermove {
    @content;
  }
  @-o-keyframes bannermove {
    @content;
  }
  @keyframes bannermove {
    @content;
  }
}
