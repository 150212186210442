.travelling-desc {
  .travelling {
    padding-left: 6px;
  }
}

.rates {
  margin-top: 32px;
}

.progress {
  box-shadow: none !important;
  height: 16px !important;
  border-radius: 0 !important;
  background: $black !important;
  margin-top: 90px;
  .progress-bar {
    width: 70%;
  }
}

.method-table {
  margin-top: 30px;
  .radio label {
    padding-left: 0 !important;
  }
}

.method-table-block {
  &:first-child {
    p {
      font-weight: bold;
    }
  }
}

.method-table-block, .method-table-middle-block {
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: top;
  font-size: 12px;
  .radio {
    margin: 0;
  }
  .method-desc {
    font-size: 10px;
  }
}

.method-table-middle-block {
  border-top: 2px solid $hr-border-color;
  border-bottom: 2px solid $hr-border-color;
}

.last-step {
  .tooltip-inner {
    background: $ff-grey-active;
    border-radius: 0 !important;
    opacity: 1 !important;
    padding: 14px 24px !important;
    font-size: 20px !important;
    color: $black;
    margin-top: -44px;
    .km-indicator {
      width: 80px;
      text-align: right;
    }
  }
  .tooltip {
    z-index: 900 !important;
    top: 272px;
    left: 50%;
    .tooltip-arrow {
      border-top-color: $ff-grey-active !important;
      border-width: 30px 15px 0 !important;
      margin-left: -14px !important;
      top: 17px;
    }
  }
}

.edit {
  color: $ff-active-blue;
  text-decoration: underline;
  font-size: 12px;
  &:hover, &:active, &:visited {
    color: $ff-active-blue;
    text-decoration: underline;
  }
}

.check-type {
  label {
    &:last-child {
      margin-left: 100px !important;
    }
  }
}

.invoicing-method {
  padding: 20px 40px !important;
  .invoicing {
    padding: 0 !important;
    .invoicing-form {
      margin-top: 25px;
    }
    .invoicing-items {
      margin-bottom: 25px;
    }
    .vat {
      margin-top: 40px;
    }
  }
}

.enty-type {
  margin: 25px 0;
}

.info-forms a, .info-forms .pointer {
  color: $ff-active-blue;
  text-decoration: underline;
}

.google-autocomplete {
  margin-bottom: 11px;
  display: block;
}
