#about-us-page {
  .about-us-header {
    background: url('https://fairfleet.com/a/webassets/aboutus/About_Us_Hero_Asset_1440x500_E1724_2020-12-07_v3.jpg') no-repeat center;
    background-size: contain;
    background-color: white;
    display: flex;
    height: 400px;
    .header-title {
      align-self: center;
      text-align: center;
      h1 {
        color: $ff-grey-active;
        font-size: 48px;
        font-family: 'Arquitecta';
        font-weight: bold;
        letter-spacing: 3px;
        margin-bottom: 70px;
        text-align: center;
      }
      h2 {
        color: #fff;
        font-size: 25px;
        font-weight: normal;
        line-height: 35px;
        max-width: 750px;
        align-content: center;
        display: inline-block;
      }
    }
    
  }
  .about-us-content {
    padding: 0;
    background: white;
    h1 {
      font-family: 'Open Sans';
      font-size: 25px;
      font-weight: normal;
      color: $ff-grey-active;
      text-transform: none;
      margin-bottom: 70px;
      margin-left: 0;
      margin-right: 0;
    }
    .what-we-do {
      p {
        font-size: 16px;
        color: #484848;
        line-height: 15px;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 60px;
        }
      }
    }
    .our-team {
      padding-bottom: 45px;
      background: $our-team-bg-color;
      .user-photo {
        padding: 0;
        text-align: center;
        img {
          margin: auto;
        }
        .member-info {
          display: none;
          top: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          background: $site-map-bg-color;
          padding: 15px;
          justify-content: center;
          align-items: center;
          h4 {
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 0;
            color: $header-menu-item-color;
          }
          p {
            font-size: 12px;
            color: $header-menu-item-color;
          }
        }
        &:hover {
          .member-info {
            display: flex;
          }
        }
      }
    }
  }
}

.row-reversed {
  flex-direction: row-reverse;
}
.text-container-about-us {
  color: #484848;
  margin: 0 30px;
}
.mission {
  font-size: 16px;
  letter-spacing: 1px;
  margin: 0 30px;
  text-align: center;
}
.our-pilots-map {
  width: 100%;
  height: auto;
}
.feature-text-container-about-us {
  width: 500px;
  color: #484848;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 1px;
  text-align: left;
}

.about-us-testimonials {
  margin-bottom: 50px;
  margin-left: 30px;
  margin-right: 30px;
}

.img-awards {
  height: auto;
  margin: 20px 50px;
  width: 180px;
}

.awards-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  position: relative;
}
