.not-found-page {
  width: 100%;
  margin: 0 auto;
  .not-found-logo {
    width: 160px;
    margin: 120px auto 20px;
  }
  h1 {
    margin: 0 0 10px;
    font-size: 36px;
    letter-spacing: 1px;
    color: $black;
    font-weight: 600;
    line-height: 49px;
  }
  h4 {
    margin: 10px 0;
    padding: 0;
    font-size: 20px;
    color: $black;
  }

  h5 {
    margin: 10px 0;
    color: $black;
    font-size: 18px;
    font-weight: bold;
  }
  ul {
    li {
      line-height: 19px;
      margin-bottom: 15px;
      a {
        text-decoration: underline !important;
        color: $ff-active-blue;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}
