.legal {
  .info-forms {
    .form-horizontal {
      padding: 20px 0 !important;
      .server-msg {
        margin-left: 6px;
      }
    }
    .validation-msg-wrapper {
      padding-top: 3px;
      padding-bottom: 11px;
    }
  }
}

.flight-form {
  padding-top: 30px;
  padding-bottom: 45px;
  padding-left: 20px;
}

.flight-permit, .insurance {
  padding-bottom: 14px;
  p {
    font-size: 12px;
    padding: 0 40px 0 20px;
  }
  .bootstrap-select {
    margin-top: 2px !important;
    width: 100% !important;
  }

  .permission-box {
    .validation-msg-wrapper {
      padding-left: 16px;
      margin-left: 4px;
    }

    .checkbox label {
      padding-left: 20px !important;
    }
  }
}

.another-permit {
  padding-top: 20px;
  font-size: 14px;
  a {
    color: $ff-active-blue;
    text-decoration: underline;
    cursor: pointer;
    &:hover, &:visited, &:active {
      color: $ff-active-blue;
      text-decoration: underline;
    }
  }
}

.flight-permit-1, .flight-permit-2, .flight-permit-3 {
  .permit-header {
    img {
      width: 18px;
      cursor: pointer;
    }
    ul {
      li {
        font-size: 12px;
        color: $header-menu-item-color;
        line-height: 24px;
      }
    }
    .view-permit {
      width: 74px;
      height: 74px;
      p {
        font-size: 12px;
        color: $header-menu-item-color;
        padding: 0 !important;

        img {
          width: 100px;
        }
      }

      img {
        width: 100%;
        height: auto;
        border: 1px solid $ff-grey-active;
      }
    }
  }
  label:not(.policy-update, .choose-file) {
    text-align: left !important;
    padding-left: 20px;
    width: 100%;
  }
  input {
    @include placeholder($ff-grey-active);
  }
  p.enter-number {
    font-size: 12px !important;
    font-style: italic !important;
    color: $ff-active-blue !important;
    padding: 10px 0 0 !important;
    margin-bottom: 0;
  }
}

.slash {
  padding: 0 34px 0 24px;
  font-size: 24px;
}

.choose-file {
  input {
    display: none;
  }
  .choose {
    margin-top: 11px;
  }
  span:first-child {
    background: $ff-active-blue;
    color: $white;
    padding: 5px 15px;
    border: 1px solid transparent;
    border-radius: 6px;
  }
  input {
    border: 0 none !important;
  }
}

.legal-labels {
  margin-top: 7px;
}

.policy-update {
  width: 140px !important;
  text-align: left !important;
  margin-right: 20px;
  padding-right: 30px;
  padding-left: 5px;
}
