.specializations {
  .specializations-title {
    margin-top: 40px;
    font-size: 12px;
  }
  form {
    .info-forms {
      h2 {
        font-family: 'Arquitecta';
        font-weight: bold;
      }
      h5 {
        margin: 15px 0 0;
        font-size: 14px;
        color: #484848;
      }
      .other-text {
        margin-top: 9px;
      }
      .specialties-blocks {
        padding: 0;
        p {
          margin-top: 10px;
          font-size: 12px;
        }
        textarea {
          resize: none;
          margin-bottom: 20px;
        }
        ul {
          margin: 0;
          padding: 10px 0;
          li {
            vertical-align: sub;
            font-size: 14px;
            small {
              font-size: 12px;
              color: $site-map-border-color;
            }
            &:first-child {
              span {
                display: block;
              }
            }
          }
        }
      }
      .p-r-0 {
        padding-right: 0;
      }
      hr {
        margin: 0 !important;
      }
    }
  }
}
