$active-span-bg-color: #7bcb23;
$add-potfolio-btn-text-color: #bbb;
$black-shadow: rgba(0, 0, 0, 0.3);
$black-transparent: rgba(0, 0, 0, 0.5);
$black: #000;
$calendar-color: #818181;
$calendar-color: #818181;
$cancel-account-btn-bg-color: #f15d5e;
$canceled-text-color: #f15d5e;
$contact-footer-bg-color: #f2f1f2;
$content-items-color: #545454;
$current-projects-bg-color: #575757;
$error-color: $canceled-text-color;
$finden-btn-bg-color: #d6edeb;
$ff-background-active: #f5f5f5;
$ff-blue: #3589c6;
$ff-dark-blue: #1b4964;
$ff-gradient: linear-gradient(180deg, #1b4964, white);
$ff-active-blue: #2979ff;
$ff-grey: #666666;
$ff-grey-active: #575757;
$ff-orange: #faa635;
$flag-span-color: #bbb;
$flag-span-color: #bbb;
$header-transparent-bg-color: rgba(72, 72, 72, 0.2);
$header-menu-item-color: #4a4a4a;
$hr-border-color: #ccc;
$light-gray: #9b9b9b;
$map-selection-bg-color: rgba(74, 74, 74, 0.97);
$message-area-bg-color: #ebebeb;
$mobile-divider-bg-color: #39393c;
$our-team-bg-color: #f2f2f2;
$packages-offset: -180px;
$page-width: 1200px;
$public-profile-bg-color: #d8d8d8;
$request-span-color: #f15d5e;
$reset-selection-bg-color: #a5d8de;
$settings-collaps-bg-color: rgba(72, 72, 72, 0.95);
$share-modal-background: #d6eef0;
$site-map-bg-color: #d8d8d8;
$site-map-border-color: #979797;
$success-color: $active-span-bg-color;
$transparent-alpha: rgba(255, 255, 255, 0.5);
$transparent: rgba(255, 255, 255, 0);
$user-info-bg-color: #eee;
$white: #fff;
$with-shadow-padding: 1140px;
