.mustermann {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid $hr-border-color;
}

form.mustermann {
  padding-left: 15px !important;
  padding-right: 15px !important;
  .bootstrap-select {
    margin-left: 8px !important;
  }
}

.add-wingman {
  margin-top: 20px;
  margin-bottom: 20px;
}

.change-password {
  p {
    font-size: 12px;
  }
  label {
    text-align: left !important;
    width: 220px !important;
  }
}

.check-emails {
  padding-bottom: 25px;
  padding-top: 25px;
  p {
    font-size: 12px;
  }
  .checkbox {
    padding-top: 0 !important;
    label {
      padding-bottom: 18px;
    }
  }
}

.account {
  padding: 40px !important;
  p {
    font-size: 12px;
  }
  .account-cancel {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .cancel-btn {
    background: $cancel-account-btn-bg-color;
    border: 1px solid $cancel-account-btn-bg-color;
    border-radius: 6px;
    color: $white;
    padding: 6px 15px;
    font-size: 12px;
    &:focus, &:visited, &:hover {
      background: $cancel-account-btn-bg-color;
      border: 1px solid $cancel-account-btn-bg-color;
      border-radius: 6px;
      color: $white;
    }
  }
}

.send-button {
  border-radius: 6px;
  color: $white;
  padding: 5px 15px;
  background: $ff-active-blue;
  border: 1px solid $ff-active-blue;
  text-decoration: none;
  margin-top: 30px;
  font-size: 12px;
  &:hover, &:visited, &:active {
    background: $ff-active-blue;
    border: 1px solid $ff-active-blue;
    text-decoration: none;
    color: $white;
  }
}

.receive-sms {
  margin-top: 15px;
  input {
    margin-top: -7px !important;
  }
}

.settings-labels {
  margin-top: 7px;
}

.about-intiatives {
  margin-bottom: 20px;
  font-size: 12px;
}

.full-access {
  margin-top: -6px !important;
}

.sccess-client {
  margin-top: 15px;
}
