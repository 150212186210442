#upload-portfolio {
  h6 {
    color: $header-menu-item-color;
    font-size: 14px;
    margin-bottom: 30px;
    &.upload-work {
      padding: 0;
      margin: 30px 0 10px;
      line-height: 22px;
    }
  }
  form {
    padding: 3px;
    width: 200px;
    height: 200px;
    background-color: $current-projects-bg-color;
    border: 1px solid $site-map-border-color;
    margin: 0 auto;
    a {
      display: block;
      text-align: center;
    }
    img {
      width: 57px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    h5 {
      font-size: 12px;
      color: $white;
      margin-bottom: 0;
      margin-top: 0;
    }
    .choose-file {
      margin-left: 43px;
    }
  }
  ul {
    padding: 0;
    li {
      line-height: 17px;
      a {
        text-decoration: underline;
        color: $ff-active-blue;
        font-size: 12px;
        &:active, &:visited, &:hover {
          text-decoration: underline !important;
          color: $ff-active-blue;
        }
      }
    }
  }
}

#add-project {
  .arrow-back {
    padding: 15px 30px 0;
    img {
      height: 20px;
      width: 20px;
    }
    span {
      color: $ff-grey-active;
      font-family: 'Arquitecta';
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 16px;
    }
  }
  .tags form {
    padding: 0;
  }
  .add-project-form {
    margin: 15px;
    .upload-window {
      .window {
        background-size: contain;
        background-position: 0;
        background-repeat: no-repeat;
        margin: 15px 0;
        padding: 0;
        height: 310px;
        border: 1px solid $site-map-border-color;
        &.uploaded {
          background-position: center;
          background-repeat: no-repeat;
          background-size: 50%;
        }
        .image-upload {
          position: absolute;
          bottom: 0;
          width: 100%;
          .choose {
            p {
              text-decoration: underline;
              color: $ff-active-blue;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
      .upload-img {
        color: $site-map-border-color;
      }
      .validation-error {
        position: relative !important;
      }
    }
    .upload-form {
      .validation-msg-wrapper {
        margin-top: 6px;
      }
      margin: 15px 0;
      .form-group {
        margin-bottom: 20px;
        .bootstrap-select, .dropdown-menu.open {
          width: 100% !important;
        }
        textarea {
          border: 1px solid $ff-grey-active;
          resize: none;
          width: 100%;
        }
        .upload-tags {
          width: 100%;
          .mat-form-field-infix {
            border-top: 0;
          }
          input {
            border: none;
          }
        }
        label {
          padding-left: 0 !important;
          span {
            &.service-type {
              display: block;
            }
          }
        }
        .checkbox {
          label {
            span {
              float: left;
              margin-top: 4px;
            }
            p {
              display: flex;
              font-size: 12px;
            }
          }
        }
        .add-btn {
          color: $add-potfolio-btn-text-color;
          border: 1px solid $site-map-bg-color;
          font-size: 14px;
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}

.media-modal {
  .validation-msg-wrapper {
    margin-left: 31px;
  }
  .media-dialog-header {
    width: 100%;
    height: 100%;
    color: $white;
    background: $ff-grey-active;
    padding: 6px 20px;
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 1px;
    font-family: 'Arquitecta';
  }
  .modal-content {
    .upload-video {
      img {
        margin-top: 16px;
      }
    }
  }
  .row {
    height: calc(100% - 37px);
  }
  .url-label {
    font-weight: 700 !important;
    margin-right: 4px;
  }
  .video-upload-field {
    width: 84%;
  }
  .confirm-video {
    padding-left: 15px;
    margin-top: 40px;
  }
  &.media-type {
    p {
      white-space: pre-line;
    }
  }
}
