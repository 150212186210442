.public-profile {
  background: $public-profile-bg-color;
  padding-top: 30px;
  .public-details {
    h4 {
      color: $ff-grey-active;
      font-size: 20px;
      font-weight: bold;
      padding: 12px 15px;
      background: $white;
      font-family: 'Arquitecta';
      margin-bottom: 0;
      margin-top: 0;
      border-bottom: 1px solid $ff-grey-active;
    }
    .details {
      background: $white;
      .details-avatar {
        margin-top: 15px;
        background: url('/assets/images/picture2.jpg');
        border-radius: 50%;
        background-size: cover;
        overflow: hidden;
        position: relative;
        height: 50px;
        width: 50px;
      }
      .details-setting {
        margin-top: 15px;
        a {
          text-decoration: none;
          img {
            width: 15px;
          }
        }
      }
      h3 {
        color: $ff-grey-active;
        margin: 5px 0;
        font-family: 'Arquitecta';
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1px;
      }
      .address {
        color: $ff-active-blue;
        font-size: 12px;
        line-height: 17px;
        margin: 0 0 15px;
      }
      .info {
        font-size: 12px;
        margin: 0 0 15px;
      }
      .hire {
        margin-bottom: 0;
        li {
          &:first-child {
            padding-right: 0;
          }
          &:last-child {
            margin-bottom: 16px;
          }
        }
        .save-button {
          margin: 10px 0 0;
          padding: 7px 16px;
          font-size: 14px;
        }
      }
      .social-icons {
        margin-bottom: 0;
        li {
          a {
            text-decoration: none;
            img {
              width: 20px;
            }
          }
        }
      }
      hr.divider {
        display: block !important;
        width: 93%;
        margin: 0 auto !important;
      }
      .more-info, .desc {
        font-size: 12px;
        color: $ff-grey-active;
        margin: 16px 0;
      }
    }

  }
  .public-projects {
    padding-bottom: 75px;
    .project {
      cursor: pointer;
      height: 105px;
      margin-right: 20px;
      margin-bottom: 60px;
      padding: 0;
      .project-img {
        display: flex;
        height: 100%;
        align-items: center;
        img {
          max-height: 100%;
          display: block;
          margin-right: auto;
          margin-left: auto;
        }
      }
      p {
        height: 44px;
        background: $white;
        font-size: 12px;
        padding: 5px;
        word-break: break-all;
        color: $ff-grey-active;
      }
    }
  }
}

#carousel-modal {
  .modal-dialog {
    width: auto;
    .modal-content {
      background-color: transparent;
      border: 0 none !important;
      .modal-header {
        border: 0 none;
        padding: 0;
        h4 {
          font-size: 12px;
          line-height: 17px;
          color: $white;
          word-break: break-all;
        }
        a {
          color: $white;
          text-decoration: none;
          font-size: 12px;
        }
        .icons {
          margin-top: -20px;
          img {
            margin-left: 16px;
            cursor: pointer;
          }
          span {
            color: $white;
            font-size: 18px;
            vertical-align: middle;
          }
        }
      }
      .modal-body {
        height: auto;
        .carousel-inner {
          width: 827px;
        }
        .carousel-indicators {
          visibility: hidden !important;
        }
        .carousel-control {
          opacity: 1;
          font-size: 70px;
          background-image: none !important;
          cursor: pointer;
          &.right {
            right: -18%;
          }
          &.left {
            left: -18%;
          }
          span {
            font-size: 130px;
          }
        }
        .video-iframe {
          border: 0;
          height: 100%;
          width: 100%;
        }
        slide.item.active {
          div.item.active {
            display: flex;
            height: 550px;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            img {
              max-height: 100%;
            }
          }
        }
      }
      .modal-footer {
        border: 0 none;
        padding-right: 0;
        padding-top: 0;
        .carousel {
          .carousel-indicators, .carousel-control {
            display: none !important;
          }
        }
        .project {
          display: flex;
          align-items: center;
          height: 105px;
          margin-right: 20px;
          margin-bottom: 20px;
          width: 190px;
          padding-left: 0;
          img {
            max-height: 100%;
          }
          &:last-child {
            margin-right: -15px;
          }
        }
      }
    }
  }
}

#share {
  .modal-dialog {
    .modal-content {
      background-color: $share-modal-background;
      border: 0 none !important;;
    }
  }
}

#delete, #discard-changes, #mark-as-done-modal, #upload-success-modal {
  z-index: 1051 !important;
  .modal-dialog {
    .modal-content {
      background-color: $ff-dark-blue;
      border: 0 none !important;
      .modal-body {
        p {
          margin-bottom: 0;
        }
      }
    }
    .modal-action {
      li {
        text-align: center;
        &:first-child {
          .save-button {
            background: $ff-grey-active;
            color: $white;
            border-radius: 6px !important;
            &:hover, &:active, &:focus, &:visited {
              background: $ff-grey-active !important;
              color: $white !important;
            }
          }
        }
        &:last-child {
          .save-button {
            background: $white;
            color: $ff-grey-active;
            border-radius: 6px !important;
            &:hover, &:active, &:focus, &:visited {
              background: $white !important;
              color: $ff-grey-active !important;
            }
          }
        }
      }
      .save-button {
        margin-top: 0 !important;
      }
    }
  }
}
