@media (max-width: 479px) {
  @import 'max479';
}

@media (min-width: 480px) and (max-width: 767px) {
  @import 'min480max767';
}

@media (min-width: 768px) and (max-width: 991px) {
  @import 'min768max991';
}

@media (min-width: 992px) and (max-width: 1199px) {
  @import 'min992max1199';
}

@media (min-width: 1200px) and (max-width: 1399px) {
  @import 'min1200max1399';
}

@media (min-width: 1400px) {
  @import 'min1400';
}

@media (min-width: 1600px) {
  @import 'min1600';
}
