.goto-link {
  color: $ff-active-blue;
  cursor: pointer;
  &:hover, &:focus {
    color: $ff-active-blue;
  }
}
.login-page {
  padding: 0;
  .logo {
    padding: 0;
    a {
      text-decoration: none;
      img {
        position: absolute;
        width: 99px;
        left: 45px;
        top: 30px;
      }
    }
  }
  .ff-cover {
    float: right;
    background-size: cover;
    position: relative;
    overflow-x: hidden;
    width: 100%;
    min-width: 550px;
    height: 100%;
    min-height: 950px;
  }
  .dark-overlay {
    background: #484848;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 950px;
  }
  form {
    input {
      border-radius: 3px !important;
      border-color: $public-profile-bg-color !important;
      &:focus {
        border-color: $ff-grey-active !important;
        &.password {
          @include placeholder($ff-grey-active);
        }
      }
      &.password {
        @include placeholder($public-profile-bg-color);
      }
    }
    .form-group {
      margin-bottom: 10px;
      .submit-login {
        padding: 6px 24px;
        margin-top: 10px;
        &:not([disabled]):hover, 
        &:not([disabled]):focus, 
        &:not([disabled]):visited, 
        &:not([disabled]):active {
          background: $ff-active-blue !important;
          border-color: $ff-active-blue !important;
          color: $white !important;
        }
      }
    }
  }
  .login-btn {
    margin-bottom: 90px !important;
    margin-top: 30px;
    button, a.btn {
      font-size: 12px;
      color: $light-gray;
      border-color: $light-gray;
      margin-top: 10px;
      text-align: center;
      display: block;
      &:hover, &:focus {
        background-color: transparent !important;
        color: $light-gray !important;
      }
    }
  }
  h4 {
    font-size: 20px;
    margin-bottom: 7px;
    font-weight: bold;
    color: $ff-grey-active;
  }
  p {
    font-size: 12px;
    color: $ff-grey-active;
    margin-bottom: 0;
    &.client-account {
      margin-bottom: 20px;
    }
  }
  .tel-number {
    .tel {
      width: 100% !important;
      padding-left: 0 !important;
    }
    .phone-dropdown {
      width: 100px !important;
      .btn-group {
        width: 100%;
      }
      button {
        border: 1px solid $public-profile-bg-color;
        height: 34px;
        padding: 6px;
        z-index: 10;
        border-radius: 3px 0 0 3px;
        width: 100% !important;
        &:focus, &:visited, &:active {
          border: 1px solid $ff-grey-active !important;
          box-shadow: none !important;
          outline: none !important;
        }
      }
    }
    .nmb-input {
      width: 70% !important;
      input {
        border-radius: 0 3px 3px 0 !important;
      }
    }
    .btn-group {
      padding-right: 0;
      padding-left: 0;
      div:last-child {
        padding-right: 0;
      }
    }
  }
}

.looking-cp {
  h3, h4 {
    color: $white !important;
  }
}
.looking-pp {
  position: absolute;
  top: 215px;
  h4 {
    color: #fff;
    font-weight: normal;
    margin-bottom: 20px;
  }
}

.client-login {
  h3 {
    font-size: 21px;
    color: $header-menu-item-color;
    position: absolute;
    margin-top: 171px;
  }
  form {
    h4 {
      margin-bottom: 30px;
    }
    .form-group {
      a {
        padding-top: 5px;
        font-size: 12px;
        color: $ff-grey-active;
        &:hover {
          text-decoration: none;
        }
        &:focus {
          outline: none !important;
        }
      }
    }
    input {
      @include placeholder($public-profile-bg-color);
      &:focus {
        @include placeholder($ff-grey-active);
      }
    }
  }
}

.part {
  width: 480px;
  margin: 97px auto;
  h5 {
    font-size: 12px;
    font-weight: bold;
    color: $site-map-bg-color;
    margin: 0;
  }
  h4 {
    font-size: 20px;
    font-weight: 600;
    color: $ff-grey-active;
    margin: 30px 0 50px;
  }
  ul {
    li {
      border: 1px solid $site-map-bg-color;
      max-width: 200px;
      max-height: 162px;
      vertical-align: top;
      &:first-child {
        margin-right: 35px;
      }
      a {
        text-decoration: none;
        margin: 21px 45px 0;
        font-size: 12px;
        display: block;
        border: 1px solid $ff-active-blue;
        border-radius: 6px;
        color: $ff-active-blue;
        width: 110px;
        padding: 6px;
        &:focus {
          background: $ff-active-blue;
          color: $white;
        }
      }
      p {
        padding: 28px 28px 48px;
      }
    }
  }
}

.part {
  width: 480px;
  margin: 97px auto;
  h5 {
    font-size: 12px;
    font-weight: bold;
    color: $site-map-bg-color;
    margin: 0;
  }
  h4 {
    font-size: 20px;
    font-weight: 600;
    color: $ff-grey-active;
    margin: 30px 0 50px;
  }
  ul {
    li {
      border: 1px solid $site-map-bg-color;
      &:first-child {
        margin-right: 35px;
      }
      a {
        text-decoration: none;
        margin: 21px 45px 0;
        font-size: 12px;
        display: block;
        border: 1px solid $ff-active-blue;
        border-radius: 6px;
        color: $ff-active-blue;
        width: 110px;
        padding: 6px;
        &:focus {
          background: $ff-active-blue;
          color: $white;
        }
      }
      p {
        padding: 15px 28px 48px;
      }
    }
  }
  small {
    margin-top: 80px;
    display: block;
    font-size: 12px;
    color: $ff-grey-active;
  }
}

.reset-psw {
  &.success-block {
    img {
      margin-bottom: 20px;
    }
    h4 {
      font-size: 20px;
      font-weight: 600;
      line-height: 27px;
      color: $header-menu-item-color;
    }
  }
  .enter-email {
    margin: 0 0 20px;
  }
  .email-reset {
    li {
      margin-top: 30px;
      .back-login {
        text-decoration: underline;
        color: $ff-grey-active;
        font-size: 12px;
        vertical-align: sub;
        margin-right: 30px;
        &:hover, &:visited, &:active {
          text-decoration: underline !important;
          color: $ff-grey-active;
        }
      }
    }
  }
}

.confirme-email {
  padding: 90px 0;
  form {
    h2 {
      font-size: 21px;
      font-weight: bold;
      margin: 0;
      color: $header-menu-item-color;
      line-height: 18px;
    }
    h5, h6 {
      font-size: 12px;
      margin: 0;
      color: $header-menu-item-color;
      line-height: 18px;
    }
    h4 {
      font-size: 12px;
      font-weight: bold;
      margin-top: 15px;
      margin-bottom: 0;
      color: $header-menu-item-color;
      line-height: 18px;
    }
    .form-group {
      padding-left: 0;
      margin-top: 15px;
      input {
        @include placeholder($public-profile-bg-color);
        border-color: $public-profile-bg-color;
        &:focus {
          @include placeholder($ff-grey-active);
          border-color: $ff-grey-active;
        }
      }
    }
    .save-button {
      border-radius: 6px;
    }
  }
}
