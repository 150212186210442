#contact-us-page {
  padding: 0;
  background: #fff;

  .main-background-container {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
  }
  .main-background-style {
    position: relative;
    display: flex;
    width: auto;
    overflow: hidden;
  }
  .main-background {
    margin-left: auto;
    margin-right: auto;
  }
  .main-background-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
  }
  .contact-content {
    h3 {
      margin-top: 30px;
      font-family: 'Arquitecta';
      color: #464646;
      font-weight: bold;
      font-size: 30px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .reason-inquiry {
      margin: 30px 0;
      .inquiry-blocks {
        cursor: pointer;
        h5 {
          font-weight: bold;
          font-size: 14px;
          color: $ff-grey-active;
          margin: 15px 0;
        }
        p {
          font-size: 12px;
          color: $ff-grey-active;
          margin: 0;
          small {
            color: $ff-active-blue;
            cursor: pointer;
          }
        }
        .sales-blocks {
          padding: 20px 15px 45px;
          span {
            visibility: hidden;
          }
          &.active {
            background: $contact-footer-bg-color;
            padding: 20px 15px 15px;
            span {
              border-top: 26px solid $contact-footer-bg-color;
              border-left: 38px solid transparent;
              border-right: 38px solid transparent;
              position: relative;
              top: 24px;
              visibility: visible;
            }
          }
          a {
            display: block;
            width: 150px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
  .contact-collapse-blocks {
    padding-top: 50px;
    h5 {
      font-weight: bold;
      color: #484848;
      font-size: 14px;
      margin: 0 0 15px;
    }
    p {
      font-size: 12px;
      line-height: 15px;
      color: $header-menu-item-color;
      &.connect-ff {
        font-size: 12px;
        color: $header-menu-item-color;
        margin-bottom: 15px;
      }
    }
    .form-group {
      label {
        font-size: 12px;
        color: $header-menu-item-color;
        line-height: 15px;
      }
      input {
        border-radius: 6px;
        border: 1px solid $site-map-border-color;
      }
      small {
        font-size: 10px;
        color: $header-menu-item-color;
        line-height: 11px;
      }
      textarea {
        resize: none;
        border-radius: 6px;
        padding: 10px;
        font-size: 12px;
        &:focus {
          outline: none !important;
        }
      }
    }
  }
  .contact-footer {
    background: $contact-footer-bg-color;
    padding-right: 0;
    li {
      line-height: 18px;
      font-family: 'Open Sans';
      font-size: 14px;
      color:#484848;
      a {
        font-size: 14px;
        font-family: 'Open Sans';
        color: #484848;
        text-decoration: none;
        &:hover, &:visited, &:active {
          text-decoration: none;
        }
      }
      &.footer-title {
        font-family: 'Arquitecta';
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    .contact-map {
      padding-right: 0;
      > div {
        height: 275px;
      }
    }
    .contact-us-vertical-center {
      display: flex;
      align-items: center;
    }

  }
  
}
