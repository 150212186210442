#landing-page,
#visitor-header-outlet {
  .visitor-page-header {
    background: $ff-dark-blue;
    width: 100%;
    .header-logo {
      img {
        margin: 8px 0;
      }
    }
    ul {
      margin: 0;
      li {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 40px !important;
        padding-right: 0 !important;
        a, .mat-button {
          color: #ffffff;
          font-family: 'Open Sans';
          font-size: 14px;
          font-weight: bold;
          line-height: 19px;
          text-align: center;
          text-decoration: none;
          border-bottom-width: 0;
        }
      }
    }
  }
  &.header-transparent .visitor-page-header {
    position: absolute !important;
    background: $header-transparent-bg-color;
  }
}
// fix for mat-menu behind visitor header FF-3761
.cdk-overlay-container {
  z-index: 1080;
}

#landing-page {
  .logos-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
  }
  .partners {
    background: $current-projects-bg-color;
    padding-bottom: 20px;
    .partners-list {
      background: $ff-grey-active;
      padding: 0;
      ul {
        padding: 11px 0;
        margin-bottom: 0;
        li {
          margin-right: 35px;
          img {
            width: 75px;
          }
        }
      }
    }
    .why-ff {
      ul {
        display: inline-block;
        padding: 0;
        margin: 18px 0 30px;
        li {
          font-size: 20px;
          font-weight: bold;
          font-family: 'Arquitecta';
          color: $white;
          line-height: 25px;
          vertical-align: text-bottom;
        }
      }
      img {
        margin-bottom: 30px;
      }
      h1 {
        font-size: 30px;
        font-weight: bold;
        margin: 30px 0;
        font-family: 'Arquitecta';
        color: $white;
        letter-spacing: 3px;
      }
    }
    .reasons-ff {
      background: $ff-grey-active;
      h6 {
        font-size: 12px;
        color: $white;
        margin: 12px 0 20px;
        span {
          margin: 0 10px;
        }
      }
      ul {
        li {
          font-size: 14px;
          line-height: 20px;
          color: $white;
          margin-bottom: 20px;
          &::before {
            content: url('/assets/images/checkmark.png');
            margin-left: -24px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .landing-content {
    .landing-videos {
      padding: 30px 0;
      background: $share-modal-background;
      .embed-responsive {
        height: 210px;
        border: 3px solid $white;
      }
      .video-gallery {
        height: 210px;
        h2 {
          margin-top: 50px;
          font-size: 30px;
          font-weight: bold;
          font-family: 'Arquitecta';
          margin-bottom: 0;
          letter-spacing: 3px;
        }
        p {
          margin-bottom: 0;
        }
        img {
          margin-top: 13px;
        }
      }
    }
    .allianz {
      img {
        margin: 30px 0;
        width: 250px;
      }
      h1 {
        margin: 0 0 18px;
        font-family: 'Arquitecta';
        color: $ff-grey-active;
        letter-spacing: 3px;
        font-size: 30px;
        font-weight: bold;
        span {
          display: block;
        }
      }
      p {
        font-size: 14px;
        line-height: 24px;
        color: $header-menu-item-color;
        margin-bottom: 200px;
      }
    }
    .about-ff, .our-pilots, .engagement {
      margin-top: -50px;
      z-index: 100;
      padding: 40px;
      background: $share-modal-background;
      h1 {
        font-weight: bold;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 4px;
        font-family: 'Arquitecta';
        color: $ff-grey-active;
        margin: 0;
      }
      ul {
        margin-top: 30px;
        li {
          font-size: 16px;
          line-height: 22px;
          color: $ff-grey-active;
        }
      }
    }
    .company-founding {
      height: 680px;
      background-color: $ff-grey-active;
      margin-top: -150px;
      .carousel {
        margin-top: 230px;
        padding: 0 40px;
        .carousel-indicators {
          bottom: -50%;
        }
        .carousel-inner {
          .item {
            h3 {
              font-size: 18px;
              font-style: italic;
              line-height: 24px;
              color: $white;
              margin: 0;
            }
            &.next, &.left {
              background-color: $transparent !important;
            }
            h4 {
              font-size: 20px;
              font-weight: bold;
              line-height: 24px;
              letter-spacing: 2px;
              font-family: 'Arquitecta';
              color: $white;
              margin: 34px 0 10px;
            }
            h6 {
              font-size: 10px;
              line-height: 14px;
              color: $white;
              margin-bottom: 20px;
            }
            .logo-placeholder {
              padding: 0;
              button {
                background-color: $ff-active-blue;
                color: $white;
                border-color: $ff-active-blue;
                border-radius: 0;
                width: 95px;
                font-size: 10px;
                padding: 6px;
                &:hover, &:focus, &:active {
                  background-color: $ff-active-blue !important;
                  color: $white !important;
                  border-color: $ff-active-blue !important;
                }
              }
            }
          }
        }
      }
    }
    .our-pilots, .engagement {
      margin-top: -150px;
    }
    .landing-map {
      position: relative;
      // top: 150px;
      margin-top: 50px;
      margin-left: 0;
      width: 100%;
      height: auto;
      img {
        width: 100%;
      }
    }
    .finden-btn {
      width: 175px;
      background-color: $ff-active-blue;
      border-color: $ff-active-blue;
      border-radius: 6px;
      color: $white;
      font-family: 'Arquitecta';
      font-weight: bold;
      font-size: 14px;
      margin: 50px 0;
      &:focus, &:hover {
        background-color: $ff-active-blue !important;
        border-color: $ff-active-blue !important;
      }
    }
    .bewerben-btn {
      width: 175px;
      background-color: transparent;
      border-color: $ff-active-blue;
      border-radius: 6px;
      color: $ff-active-blue;
      font-family: 'Arquitecta';
      font-weight: bold;
      font-size: 14px;
      margin: 50px 0;
    }
    .select-industry {
      background: $ff-grey-active;
      h1 {
        margin-top: 30px;
        font-weight: bold;
        color: $white;
        font-family: 'Arquitecta';
        letter-spacing: 3px;
        margin-bottom: 0;
      }
      h6 {
        font-size: 12px;
        color: $white;
        margin: 0;
      }
      ul {
        margin-top: 34px;
        margin-bottom: 210px;
        li {
          p {
            color: $white;
            margin-top: 10px;
          }
        }
      }
    }
    .engagement {
      padding: 40px 20px;
    }
  }
}

.landing-footer .footer {
  padding-top: 15px;
  background-color: $ff-dark-blue;
}

.industry-details-title {
  position: relative;
  color: #ffffff;
  font-family: Arquitecta;
  font-size: 48px;
  font-weight: 900;
  line-height: 58px;
  text-align: center;
}

.industry-details-subtitle {
  position: relative;
  height: 70px;
  width: 100%;
  max-width: 753px;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}

.industry-info-container {
  align-items: center;
  align-self: center;
  letter-spacing: 1px;
  position: absolute;
  width: 100%;
}

.visitor-order-funnel-container {
  position: relative;
  margin: 0 auto 60px;
  color: #484848;
  background-color: #ffffff;
  .visitor-order-funnel-main {
    .visitor-order-funnel-left {
      h2 {
        margin-top: 0;
      }
      margin-bottom: 50px;
    }
    .package-details-right {
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .package-details-page-container {
        display: inline-block;
      }
    }
    .login-page {
      margin-top: 50px;
    }
  }
}

.package-details-wrapper {
  display: inline;
  .package-details-container {
    position: relative;
    color: $ff-grey-active;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    max-width: 375px;
    min-width: 285px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 80px;
    border: 1px solid #d5edec;
    letter-spacing: 1px;
  }
}

.request-details-container {
  position: relative;
  color: $ff-grey-active;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  max-width: 750px;
  min-width: 285px;
  margin: auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 50px;
  border: 1px solid #d5edec;
  letter-spacing: 1px;
}

.package-details-title {
  color: #484848;
  font-family: Arquitecta;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  margin-bottom: 20px;
  margin-top: 25px;
  text-align: center;
  white-space: pre-line;
}

.package-light {
  color: #ffffff !important;
  font-family: Arquitecta;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-align: left !important;
}

.package-details-price {
  color: $ff-grey-active;
  font-family: Arquitecta;
  font-size: 50px;
  letter-spacing: 1px;
  line-height: 61px;
  text-align: center;
  margin-bottom: 20px;
}

.small-star-price {
  font-size: 35px;
  vertical-align: top;
}

.package-starting-from-text {
  font-size: 25px;
}

.package-details-subtitle {
  color: $ff-grey-active;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 15px;
  text-align: center;
  white-space: pre-line;
}

.package-details-is-featured {
  height: 30px;
  line-height: 30px;
  position: relative;
  border-radius: 3px 3px 0 0;
  background-color: $ff-active-blue;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.package-details-not-featured {
  margin-top: 30px;
}

.package-more-details-button {
  height: 35px;
  width: 100%;
  color: $ff-grey-active;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  line-height: 35px;
  text-align: center;
  position: absolute;
  bottom: 15px;
  padding-bottom: 70px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    color: $ff-active-blue;
  }
}

.list-of-packages-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.package-details-checkbox {
  position: relative;
  width: 18px;
  vertical-align: '';
  margin: 0;
  margin-left: 15px;
}

.package-details-services {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}

.service-style {
  line-height: 20px;
}

.powered-by-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.powerd-by-item {
  width: 250px;
  height: 80px;
}

.industry-slogan {
  height: 35px;
  max-width: $page-width;
  color: #484848;
  font-family: 'Open Sans';
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 70px;
  letter-spacing: 1px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.advantages-container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.advantage-image {
  height: 72px;
  margin-bottom: 30px;
}

.advantage-title {
  font-family: Arquitecta;
  font-size: 20px;
  font-weight: 900;
  line-height: 32px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.bold-style {
  font-weight: bold;
}

.white-style {
  color: #ffffff !important;
}

.advantage-content {
  color: #484848;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 30px;
  text-align: center;
}

.advantages-list {
  display: flex;
  justify-content: space-around;
  list-style: none;
  flex-wrap: wrap;
}

.advantage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 358px;
}

.media-thumbs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  width: 100%;
  justify-content: center;
  margin: 15px 0 0;
}

.media-main {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  position: relative;
  text-align: center;
  width: 100%;
  iframe {
    height: calc((100vw - 30px) / 16 * 9); //screen-width - paddings at 16:9 proportion
    max-height: calc((100vh - 230px)); //screen-height - bottom tiles
  }
}
@media screen and (max-height: 600px) {
  .media-main iframe {
    max-height: calc((100vh - 30px)); //screen-height - paddings
  }
}

.media-item {
  border: 0;
  max-width: 1140px;
  width: 100%;
}

.media-item-headline {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  // background: linear-gradient(#575757, 50%, transparent);
  // background-color: #ffffff;
  // box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  height: 10%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
}

.media-item-headline-text {
  color: white;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  margin: 10px 5px;
}

.media-thumb-item {
  cursor: pointer;
  margin: 35px;
  width: 161px;
  height: 128px;
}

.media-thumb {
  height: 130px;
  width: 160px;
}

.media-thumb-text {
  cursor: default;
  margin-top: 5px;
  text-align: center;
}

.media-slider-handle {
  --handle-size: 130px;
  --handle-opacity: 0.8;
  --handle-opacity-active: 0.5;
}

.media-custom-handle {
  width: var(--handle-size);
  height: var(--handle-size);
}

.feature-image {
  max-width: 555px;
  width: 100%;
  max-height: 350px;
}

.feature-image-container {
  height: 350px;
  overflow: hidden;
  position: relative;
  width: 555px;
}

.feature-image-container-about-us {
  overflow: hidden;
  position: relative;
  width: 555px;
}

.cut-rectangle-left {
  position: absolute;
  height: 86.27px;
  width: 86.27px;
  transform: rotate(45deg);
  background-color: #ffffff;
  top: 38%;
  left: -46px;
}

.cut-rectangle-right {
  position: absolute;
  height: 86.27px;
  width: 86.27px;
  transform: rotate(45deg);
  background-color: #ffffff;
  top: 38%;
  right: -46px;
}

.feature-row-container {
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.feature-row-container-about-us {
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.feature-text-container {
  font-size: 16px;
  color: #484848;
  font-family: 'Open Sans';
  letter-spacing: 1px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  min-width: 360px;
  min-height: 210px;
  width: 500px;
  text-align: left;
}

.feature-text-title {
  font-size: 16px;
  font-weight: bold;
}

.inspect-container {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  margin: auto;
}

.inspect-logo-container {
  display: flex;
  justify-content: center;
  margin: 30px 0 70px;
  .inspect-logo {
    width: 300px;
  }
}

.inspect-section-headline {
  margin-bottom: 30px;
}

.inspect-section-image {
  position: relative;
  img {
    max-width: 1140px;
    width: 100%;
    height: 100%;
  }
  .poi-dot {
    align-items: center;
    animation-delay: 5;
    animation: pulse 2000ms infinite;
    background-color: $ff-blue;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    height: 30px;
    justify-content: center;
    margin: 10px;
    position: absolute;
    width: 30px;
    transform: scale(1);
    text-align: center;
  }
  .poi-dot-1 {
    left: 50%;
    top: 50%;
  }
  .poi-dot-2 {
    left: 25%;
    top: 70%;
  }
  .poi-dot-3 {
    left: 50%;
    top: 75%;
  }
  .poi-dot-4 {
    left: 34%;
    top: 68%;
  }
  .poi-dot-5 {
    left: 70%;
    top: 21%;
  }
  .poi-dot-6 {
    left: 78%;
    top: 40%;
  }
  .poi-dot-7 {
    left: 69%;
    top: 79%;
  }
  .poi-dot-8 {
    left: 25%;
    top: 25%;
  }
  .popUp {
    animation: fadeIn 1000ms;
    background-color: $ff-blue;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    line-height: 21px;
    margin-left: 30px;
    max-width: 150px;
    min-width: 150px;
    padding: 9px 13px;
    position: absolute;
    z-index: 1000;
  }
  
  /* Add animation (fade in the popup) */
  @-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(53, 137, 198, 0.9);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(53, 137, 198, 0);

  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(53, 137, 198, 0);

  }
}

.inspect-section-text {
  margin-bottom: 70px;
  text-align: center;
}

.inspect-section {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.plus-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  text-align: center;
}

.plus-logo {
  margin-bottom: 20px;
}

.plus-details {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.plus-content {
  max-width: 250px;
}

.plus-details-checkbox {
  margin-right: 15px;
  position: relative;
  width: 15px;
}

.blue-button {
  &:hover {
    background-color: $ff-active-blue;
    color: #ffffff;
  }
  height: 40px;
  width: 175px;
  border-radius: 6px;
  background-color: $ff-active-blue;
  border-color: $ff-active-blue;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
  border: 0;
}

.how-it-works-container {
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 100px;
}

@supports (-ms-accelerator: true) { /* Edge only */
  .how-it-works-container {
    justify-content: space-around;
  }
}

.how-it-works-main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
}

.how-it-works-dron-icon {
  height: 35px;
  width: 50px;
}

.how-it-works-text-box {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.how-it-works-text {
  color: $ff-grey-active;
  padding-left: 30px;
}

.how-it-works-steps-headline {
  color: $ff-grey-active;
  font-family: Arquitecta;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 32px;
  padding-left: 30px;
  text-transform: uppercase;
}

.how-it-works-item {
  display: flex;
  padding-top: 30px;
}

.how-it-works-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.how-it-works-right {
  align-self: center;
  position: relative;
  max-width: 700px;
}

.how-it-works-video {
  border: 0;
  width: 100%;
}

.how-it-works-title {
  box-shadow: 0 -10px 20px -5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: $ff-grey-active;
  font-family: 'Open Sans';
  font-size: 25px;
  line-height: 35px;
  padding: 100px 0 70px;
  position: relative;
  text-align: center;
}

.about-fairfleet {
  height: 486px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
}
.fairfleet-logo-image {
  height: 60px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 232px;
}

.about-text-header {
  align-self: center;
  font-family: 'Open Sans';
  font-size: 25px;
  letter-spacing: 1px;
  margin: 0 auto;
  max-width: 750px;
  margin-bottom: 70px;
  text-align: center;
}

.about-text {
  align-self: center;
  color: #484848;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 1px;
  margin: 0 auto;
  max-height: 90px;
  max-width: 750px;
  text-align: center;
}

.pilot-cta-button {
  margin-left: 50px !important;
}

.blue-outline-button {
  background-color: #ffffff;
  border-radius: 6px;
  border: 2px solid $ff-active-blue;
  color: $ff-active-blue;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  line-height: 13px;
  margin-left: 15px;
  margin-right: 15px;
  min-width: 180px;
  &:hover {
    color: #ffffff;
    background-color: $ff-active-blue;
    border-color: $ff-active-blue;
  }
}

.arrange-appointment-button {
  margin-bottom: 15px;
}

.more-about-us-button {
  &:hover {
    background-color: $ff-active-blue;
    color: #ffffff;
  }
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
}

.why-fairfleet {
  background-color: #ffffff;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  margin: 0 auto;
  max-width: 1148px;
}

.why-fairfleet-main {
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  z-index: 10;
  letter-spacing: 1px;
  color: #484848;
  font-family: 'Open Sans';
  font-weight: normal;
  align-items: center;
}

.why-fairfleet-item-icon {
  height: 100px;
  margin: 20px;
  width: 100px;
}

.why-fairfleet-title {
  margin: 0 auto;
  color: #484848;
  font-family: 'Open Sans';
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 1px;
  margin-top: 100px;
  margin-bottom: 40px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
}

.why-fairfleet-list-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
}

.why-fairfleet-items {
  align-items: center;
  align-self: baseline;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  width: 300px;
  p {
    text-align: center;
    max-width: 300px;
  }
}

.why-fairfleet-headline {
  color: #484848;
  font-family: Arquitecta;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.why-fairfleet-bottom {
  height: 22px;
  color: #484848;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  margin: 0 auto;
}

.why-fairfleet-button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  margin-top: 30px;
}

.random-seo-main {
  position: relative;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding: 15px;
  box-shadow: 0 -10px 20px -5px rgba(0, 0, 0, 0.1);

}

.random-seo-span {
  color: $ff-grey-active;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 30px;
  max-width: 1140px;
  text-align: center;
  white-space: pre-line;
}

.other-industries-title {
  color: $ff-grey-active;
  font-family: 'Open Sans';
  font-size: 25px;
  text-align: center;
  margin: 70px 0;
}

hr {
  display: block;
  border: 0;
  margin: 1em 0;
  padding: 0;
  &.footer-divider {
    margin-top: 55px;
    margin-bottom: 70px;
  }
}

h1 {
  color: $ff-grey-active;
  font-family: Arquitecta;
  font-size: 48px;
  font-weight: 900;
  line-height: 58px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

h3 {
  color: #484848;
  font-family: 'Open Sans';
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 30px;
}

.package-details-main {
  h2 {
    color: #484848;
    font-family: 'Open Sans';
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 30px;
  }
  padding-top: 100px !important;
  padding-bottom: 50px;
}

.p-justify {
  white-space: pre-line;
}

.package-details-rating {
  height: 80px;
  width: $page-width;
  background-color: #f7f7f7;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.package-details-rating-text {
  color: $ff-grey-active;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 30px;
  margin: 0 auto;
}

.package-details-container-dark {
  color: #ffffff;
  background-color: $ff-grey-active;
  width: 100%;
  height: 340px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.other-package-details-container {
  width: 350px;
  height: 320px;
  margin-left: 20px;
  margin-right: 20px;
}

.package-details-list-left {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left !important;
}

.book-dark {
  margin-left: 0;
}

.other-packages-container {
  width: 100%;
  box-shadow: 0 -10px 100px 0 rgba(0, 0, 0, 0.1);

  background-color: $white;
  margin-top: 100px;
  padding-bottom: 100px;
}

.packages-details-shadow,
.other-packages-item {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.other-packages-main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  position: relative;
}

.terms-and-conditions {
  padding-left: 5px;
}

.other-packages-title {
  color: #484848;
  font-family: 'Open Sans';
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 70px;
  margin: 0 auto;
  padding-bottom: 70px;
  padding-top: 70px;
  text-align: center;
  width: 100%;
}

.random-seo-title {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  line-height: 150px;
}

.random-seo-text {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  max-width: 600px;
}

.other-industries-container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1140px;
  margin: 0 auto;
}

.other-industries-item {
  &:hover {
    cursor: pointer;
  }
  color: $ff-grey-active;
  font-family: Arquitecta;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  width: 280px;
  height: 70px;
  letter-spacing: 1px;
  a {
    color: $ff-grey-active;
    text-decoration: none;
    &:hover {
      color: $ff-grey-active;
    }
    &:active,
    &.active {
      text-decoration: underline;
    }
  }
}

.address-summary-main {
  flex-direction: column;
  height: 395px;
  background-color: #f7f7f7;
  padding: 30px;
}

.address-summary-main h3 {
  float:left;
  width:50%;
}

.address-summary-main button {
  float:right;
  margin-top:15px;
}

.address-thumbnail-container {
  float: left;
  width:100%;
}

.order-funnel-select-flight {
  width: 555px;
}

.order-funnel-delivery-note {
  font-size: 12px;
  margin-top: 10px;
}

.address-thumbnail-map {
  width: 100%;
  height: 200px;
}

.package-details-large {
  max-width: 560px !important;
}

.label-value-main {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.label-value-value {
  font-weight: 900;
}

.personal-data-main {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding: 30px;
  a {
    color: #484848;
    .required {
      color: $request-span-color;
    }
  }
}

.router-outlet-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.inner-order-funnel-container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.title-style {
  height: 100%;
  color: #ffffff;
  font-family: Arquitecta;
  font-size: 48px;
  font-weight: 900;
  line-height: 58px;
  text-align: center;
  width: 100%;
  max-width: 800px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 2px;
}

.select-industry-button {
  background-color: $ff-active-blue;
  border-color: $ff-active-blue;
  border-radius: 6px;
  border: 0;
  color: #ffffff;
  font-size: 14px;
  height: 40px;
  letter-spacing: 1px;
  line-height: 13px;
  min-width: 180px;
  margin-top: 20px;
}

.label-logo-class {
  display: flex;
  flex-direction: column;
  align-content: center;
}
//new logos
.label-logo-image {
  width: 148px;
  height: 36.7px;
}

.logos-container {
  width: 100%;
}

.logo-container {
  float: left;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  p {
    color: #ffffff;
    text-align: center;
  }
  p.dark {
    color: $ff-grey-active;
  }
}

.industry-black-arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 30px;
  bottom: 30px;
}

.logos-class {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  bottom: 50px;
  align-items: center;
}

.arrow-down-class {
  width: 30px;
  height: 30px;
}

.select-industry-main {
  background-color: #ffffff;
}

.rating-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f7f7f7;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
}

.rating-span {
  height: 30px;
  color: #484848;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 30px;
  text-align: center;
}

.select-industry-blab {
  max-width: 753px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #484848;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  position: relative;
  display: block;
  margin-top: 46px;
  align-self: center;
}

.select-industry-title {
  height: 35px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #484848;
  font-family: 'Open Sans';
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  position: relative;
  display: block;
  margin-top: 90px;
  margin-bottom: 70px;
}

.select-industry-container {
  display: flex;
  flex-direction: column;
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
}

.arrange-appointment-container {
  margin-top: 70px;
}

.pilot-map-details {
  position: relative;
  bottom: 500px;
  /* left: 100px; */
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 650px;
  width: 100%;
  height: 415px;
  background-color: #ffffff;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
}

.pilot-map-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: 50px;
}

.pilot-map-title {
  color: #484848;
  font-family: Arquitecta;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  margin-left: 50px;
  letter-spacing: 1px;
}

.pilot-map-span {
  color: #484848;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
}

.pilot-map-img {
  width: 30px !important;
  height: 30px;
  margin-right: 25px;
}

.price-disclaimer {
  top: 30px;
  position: relative;
  height: 100%;
  max-width: 1141px;
  width: 100%;
  color: #484848;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

.list-of-packages-main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $packages-offset !important;
  margin-bottom: 105px;
}

.packages-offset {
  top: $packages-offset;
  position: relative;
}

.media-gallery-container {
  margin-top: 100px;
  padding-bottom: 100px;
}

.feature-row-main {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  margin-top: 100px !important;
  margin-bottom: 100px;
}

.container-fluid-industry {
  width: 100%;
}

.feature-row-main-about-us {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.container-fluid-no-padding {
  padding-left: 0;
  padding-right: 0;
}

.visitor-button {
  &:hover {
    background-color: $ff-active-blue;
    color: #ffffff;
  }
  height: 40px;
  width: 175px;
  border-radius: 6px;
  background-color: $ff-active-blue;
  border-color: $ff-active-blue;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
  border: 0;
}

.book-now-button {
  margin-top: 35px;
  margin-bottom: 35px;
}

.here-is-what-you-get {
  color: #484848;
  font-family: 'Open Sans';
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 70px;
  text-align: center;
}

.back-to-industry {
  width: 225px !important;
  margin-top: 0 !important;
  position: relative;
}

.orange-button {
  height: 40px;
  width: 100%;
  border: 2px solid $ff-active-blue;
  border-radius: 6px;
  background-color: $ff-active-blue;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 14px !important;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
  margin-top: 30px;
  &[disabled] {
    color: inherit;
  }
}

.address-selection-main {
  display: flex;
  flex-direction: column;
  height: auto;
}
.overlayHelp {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.searchForm {
  z-index: 2;
  height:auto;
  background-color: white;
  padding: 0 10px;
}
.helpOverlay {
  margin: 20px 5% 0;
  position: absolute;
  width:90%;
  z-index: 400;
}

.animationFadeOut {
  animation: fadeOut 1500ms forwards;
}

@keyframes fadeOut {
  1% {
    opacity: 1;
    visibility: visible;
    height: auto;
  }
  99% {
    opacity: 0;
    visibility: visible;
    height: auto;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }
}

.address-selection-main .map-wrapper {
  height:100%;
  width:100%;
  overflow: hidden;
}

.areaDetail {
  height:20px;
}

// Nested DIVs to access the circles of the AOI (while Drawing)
.address-selection-main .map-wrapper, .map-selection-container .map-wrapper {
  .gm-style-iw, .gm-style-iw-t {
    visibility: hidden;
  }
  
  div {
    div {
      div {
        div {
          div {
            div {
              div {
                div {
                  div {
                    @media (pointer: coarse) {
                      width: 20px !important;
                      height: 20px !important;
                      left: 5px !important;
                      top: 5px !important;
                      border-radius: 10px !important;
                    }
                    @media (pointer: fine) {
                      width: 20px !important;
                      height: 20px !important;
                      left: -5px !important;
                      top: -5px !important;
                      border-radius: 10px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.half-left {
  width:45% !important;
  float:left;
}

.half-right {
  width:45% !important;
  float:right;
}

.map-area {
  height:400px;
  position: relative;
}

.inputAddress {
  z-index: 2;
  float:left;
  width:calc((100% - 175px));
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color:#e0e0e0;
  padding-right: 20px;
  margin:10px 0;
}

.address-selection-main .secondary, .address-selection-main .choose span:first-child {
  background-color: #e6e5e5;
  border-color: #adadadad;
  color: #333333;
}

.inputAddress .mat-form-field-wrapper {
  padding:0;
}

.choose-file .choose {
  cursor: pointer;
}

.selected-address {
  float:left;
  width:100%;
}

.choose-file {
  float: right;
  margin-top: 20px;
  width:150px;
  margin-left:25px;
}

.map-place-marker {
  z-index: 1000;
  position: absolute;
  top:10px;
  right:10px;
  width:auto;
  margin:0;
}
@media (min-width: 768px) and (max-width: 991px) {
    
  .inputAddress {
    float:left;
    width:100%;
    border-right-width: 0;
  }

  .choose-file {
    float: right;
    margin: 0 0 20px;
    width:100%;
  }
}

.map-place-marker:active, .map-place-marker:focus {
  background-color: $ff-active-blue !important;
}

.address-confirmation-divider {
  box-sizing: border-box;
  height: 1px;
  border: 0.5px solid $ff-grey-active;
}

.mat-list-container {
  margin: 0 auto;
  margin-bottom: 100px;
  max-width: 1150px;
  width: 100%;
}

.industry-main-image-container {
  :hover {
    opacity: 0.8;
    cursor: pointer;
  }
  color: #ffffff;
  a {
    color: inherit;
    text-decoration: none;
  }
}

.industry-main-image-container:hover .hide-arrow-when-hover {
  display: none;
}

.see-more-button {
  height: 40px;
  width: 120px;
  border: 2px solid $ff-active-blue;
  border-radius: 6px;
  color: $ff-active-blue;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
}

.industry-main-image-text-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding: 30px;
  position: relative;
  white-space: pre-line;
  width: 350px;
}

.green-done-rectangle {
  width: 100%;
  height: 30px;
  border-radius: 5px 5px 0 0;
  background-color: #6ab711;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
}

.confirmation-item-class {
  display: flex;
  align-items: center;
}

.confirmation-item-img {
  width: 80px;
  margin-right: 15px;
}

.book-another-package-class {
  align-items: center;
  background-color: $ff-grey-active;
  display: flex;
  flex-direction: column;
  height: 285px;
  width: 100%;
}

.industry-gallery-title {
  font-family: Arquitecta;
  font-size: 20px;
  position: relative;
  letter-spacing: 1px;
}

.industry-gallery-title-no-overlay {
  left: 30px;
  line-height: 28px;
  margin: 0;
  max-width: 325px;
  pointer-events: none;
  position: absolute;
  top: 30px;
}

.industry-short-description {
  flex-grow: 1;
  text-align: left;
  margin: 0 0 10px;
  position: relative;
  top: 60px;
  width: 100%;
}

.background-gradient-style {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.industry-main-image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 500ms ease;
  background-color: #000000;
}

.see-more-button {
  height: 40px;
  width: 120px;
  border: 2px solid $ff-active-blue;
  border-radius: 6px;
  color: $ff-active-blue;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
}

.black-outline-button {
  height: 40px;
  width: 166px;
  color: white;
  border: 2px solid $ff-active-blue;
  border-radius: 6px;
  background-color: $ff-active-blue;
  align-self: flex-start;
  opacity: 1 !important;
}

.login-transparent-button {
  background-color: Transparent;
  background-repeat: no-repeat;
  height: 40px;
  width: 82px;
  border: 2px solid #ffffff !important;
  border-radius: 5px;
  color: #ffffff;
  font-family: 'Open Sans';
  text-align: center;
  position: relative;
}

.title-button-container {
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: $page-width;
  width: 100%;
  z-index: 1;
}

.contact-header {
  position: absolute;
}

.visitor-header {
  z-index: 1050;
  position: relative;
}

.uppercase-style {
  text-transform: uppercase;
}

.media-icons-container {
  display: flex;
  flex-direction: row;
  width: 165px;
  justify-content: space-between;
  padding-bottom: 10px;
}

.media-icon-item {
  &:hover {
    cursor: pointer;
  }
  width: 18px;
  height: 18px;
}

.visitor-menu-hover {
  &:hover {
    cursor: pointer;
  }
}

.last-row-footer {
  margin-bottom: 70px;
  margin-top: 40px;
}

.main-video-container {
  align-items: center;
  background: #000000;
  display: flex;
  height: 100vh;
  justify-content: center;
  min-height: 600px;
  overflow: hidden;
  position: relative;
  .main-video,
  .main-image {
    /* Set opacity on the video itself instead on adding an overlay over it,
    and set the background of the container to black */
    opacity: 0.5;

    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser
    from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.testimonials-container {
  display: flex;
  flex-direction: column;
  max-width: 1148px;
  text-align: center;
}

.testimonials-logo {
  align-self: center;
  max-width: 150px;
}

.testimonials-text {
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 1px;
  color: #484848;
  max-width: 750px;
}
.testimonials-name {
  align-self: center;
  margin-top: 34px;
  margin-left: auto;
  margin-right: auto;
}
.testimonials-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-chevron {
  &:hover {
    cursor: pointer;
  }
  width: 15px;
  margin-left: 15px;
}
.right-chevron {
  &:hover {
    cursor: pointer;
  }
  width: 15px;
  margin-right: 15px;
}

.padding {
  padding-left: 20px;
  padding-right: 20px;
}

.package-details-services-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 285px;
  max-width: 375px;
}

.phone-icon-style {
  position: fixed;
  left: 0;
  top: 100px;
  cursor: pointer;
  z-index: 1050;
}

.mail-icon-style {
  position: fixed;
  left: 0;
  top: 155px;
  cursor: pointer;
  z-index: 1050;
}

.floating-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: $ff-active-blue;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.floating-phone-content,
.floating-mail-content {
  position: fixed;
  left: 50px;
  top: 100px;
  height: 50px;
  width: 240px;
  color: #000000 !important;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 52px;
  z-index: 1050;
  padding-right: 15px;
  padding-left: 5px;
  font-family: 'Open Sans';
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.floating-mail-content {
  top: 155px;
}

.slide-in {
  animation: slide-in 500ms forwards;
  -webkit-animation: slide-in 500ms forwards;
}

.slide-out {
  animation: slide-out 500ms forwards;
  -webkit-animation: slide-out 500ms forwards;
}

@keyframes slide-in {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(-100%); }
}

.floating-phone-content-a {
  color: $ff-grey-active;
  &:hover {
    color: $ff-active-blue;
    text-decoration: none;
  }
}

.phone-icon {
  color: white;
}

.mobile-login-button {
  position: absolute;
  right: 5px;
  top: 5px;
}

// Hide scrolling:
// https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll
.flex-container-logos {
  padding: 0;
  margin-top: 100px;

  overflow-x: scroll;
  width: 15000px;

  -ms-overflow-style: none;  // IE 10+
  overflow: -moz-scrollbars-none;  // Firefox
}

.flex-container-logos::-webkit-scrollbar {
  display: none;  // Safari and Chrome
}

.flex-item-logos {
  padding: 15px;
  margin-top: 5px;
  max-width: 150px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clients-container {
  max-width: 1148px;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
}

.clients-container-about-us {
  max-width: 1148px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 50px;
}
.img-logos {
  height: 70px;
  margin-left: 50px;
  margin-right: 50px;
  max-width: 150px;
  transition: all 30ms ease-in-out;
  width: 120px; //fixed to 120px for IE, and logos stack below each other
}

.first {
  -webkit-animation: bannermove 20000ms linear infinite;
  -moz-animation: bannermove 20000ms linear infinite;
  -ms-animation: bannermove 20000ms linear infinite;
  -o-animation: bannermove 20000ms linear infinite;
  animation: bannermove 20000ms linear infinite;
  transition: all 30ms ease-in-out;
}

@include keyframes( bannermove ) {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -3080px;
  }
}
.img-featured-on {
  height: 70px;
  margin: 20px;
  max-width: 150px;
  width: 120px; //fixed to 120px for IE, and logos stack below each other
}

.featured-on-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: -400px; // unfortunately hack because box of pilot map stacks below image
  padding-bottom: 50px;
  position: relative;
}

.trust-box {
  align-self: center;
  height: 50px;
  margin: 20px;
}
