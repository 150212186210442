.equipment {
  p {
    font-size: 12px;
    &.equipment-title {
      margin-top: 25px;
    }
  }
  .info-forms {
    margin-top: 20px !important;
    form {
      padding: 40px 0 20px !important;
    }
  }
  .special-equipment {
    padding: 40px 0 10px !important;
    font-size: 12px;
    margin-bottom: 0;
  }
  .special-equipment-not-listet {
    padding: 10px 0 20px !important;
    font-size: 12px;
    margin-bottom: 0;
  }
  .other-equipment {
    li {
      padding-bottom: 7px;
      font-size: 12px;
      p {
        color: $ff-active-blue;
        text-decoration: underline;
        &:hover {
          color: $ff-active-blue;
          text-decoration: underline;
        }
      }
    }
  }
  .create-package {
    margin-top: 25px;
    p {
      color: $ff-active-blue;
      text-decoration: underline;
      font-size: 14px;
      &:hover {
        color: $ff-active-blue;
        text-decoration: underline;
      }
    }
  }
  .equipment-prop {
    margin-top: 6px;
    display: block;
    font-size: 12px;
  }
}

.equipment-content {
  h2 {
    i {
      font-size: 12px;
    }
    span {
      img {
        width: 20px;
      }
    }
  }
}

.equipment-labels {
  margin-top: 7px !important;
}
