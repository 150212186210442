.form-horizontal .control-label {
  text-align: left;
}

.more-work {
  padding-left: 3.125%;
}

.login-btn {
  p {
    margin-right: 25px;
    display: inline-block !important;
  }
  button, a.btn {
    display: inline-block !important;
    vertical-align: baseline;
  }
}

.m-left40 {
  margin-left: -41px !important;
}

.m-left20 {
  margin-left: -22px !important;
}
