.legal-pages {
  min-height: 230px;
  background-color: #fff;
  .content-menu-legal {
    position: relative;
    li {
      padding: 0 0 10px !important;
      &.piloten {
        margin-left: 20px;
      }
    }
  }
  .description {
    h1 {
      color: $ff-grey-active;
      font-family: 'Arquitecta';
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 30px;
      margin: 0;
      text-transform: uppercase;
    }
    p {
      font-size: 12px;
      font-family: Open Sans;
      color: $header-menu-item-color;
      line-height: 16px;
      margin-top: 14px;
      margin-bottom: 0;
      &.safety {
        margin-bottom: 15px;
      }
      &.privacy-hier {
        margin-bottom: 47px;
      }
    }
    h2 {
      font-size: 18px;
      font-family: Open Sans;
      color: $header-menu-item-color;
      // line-height: 15px;
      margin-top: 15px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 16px;
      font-family: Open Sans;
      color: $header-menu-item-color;
      line-height: 15px;
      margin-bottom: 20px;
    }
    .bmvi {
      color: $ff-active-blue;
      font-size: 12px;
      margin-bottom: 110px;
      &:hover {
        text-decoration: none;
      }
      &:active, &:visited {
        color: $ff-active-blue;
      }
    }
    .bmvi2 {
      color: $ff-active-blue;
      font-size: 18px;
      margin-bottom: 110px;
      &:hover {
        text-decoration: none;
      }
      &:active, &:visited {
        color: $ff-active-blue;
      }
    }
    ul {
      margin-bottom: 47px;
      li {
        font-size: 12px;
        color: $header-menu-item-color;
        line-height: 24px;
        margin-bottom: 20px;
        h2 {
          font-size: 18px;
          font-family: Open Sans;
          font-weight: normal;
          margin-bottom: 20px;
          margin-top: 40px;
        }
        h3 {
          font-size: 16px;
          font-family: Open Sans;
          font-weight: normal;
          margin-bottom: 20px;
        }
        h4 {
          font-size: 14px;
          font-family: Open Sans;
          font-weight: normal;
          margin-bottom: 35px;
        }
        p {
          font-size: 12px;
          margin-bottom: 35px;
        }
      }
    }
    &.privacy-description {
      h5 {
        font-size: 12px;
        margin-top: 47px;
      }
      p {
        font-size: 12px;
      }
      ul.privacy-list {
        margin-top: 47px;
        li {
          margin-bottom: 20px;
          line-height: 15px;
          p {
            margin-bottom: 15px;
          }
          h1 {
            font-family: 'Arquitecta';
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 20px;
            color: #464646;
            line-height: 15px;
            margin-bottom: 47px;
          }
        }
      }
    }
    &.nut-description {
      h5 {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 47px;
      }
      p {
        margin-bottom: 30px;
      }
      ul {
        li {
          margin-bottom: 15px;
          font-size: 12px;
          line-height: 15px;
          p {
            margin-bottom: 15px;
          }
          h1 {
            margin-top: 47px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.content-menu-legal-link {
  color: #484848;
}
