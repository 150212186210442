.form-horizontal .control-label {
  text-align: left;
}
.equipment-dropdowns {
  padding: 0 15px 40px !important;

  label {
    &:first-child {
      margin-top: 0 !important;
    }
  }
}

.special {
  margin-left: 40px;
}

.quotes-content {
  .method-table-block {
    p {
      padding: 24px 0 24px 7px !important;
    }
  }
  .p-0 {
    padding: 0 !important;
  }
  .create-quote {
    padding: 0 4px 0 0 !important;
  }
}

.more-work {
  padding-left: 3.125%;
}

.activities {
  .recent-activities {
    .edit {
      padding-top: 15px !important;
    }
  }
}

.profile {
  .profile-details {
    .profile-avatar {
      height: auto !important;
    }
    .profile-dropdown {
      .choose-dropdown {
        .bootstrap-select {
          width: 200px !important;
        }
      }
    }
    .profile-country {
      .bootstrap-select {
        width: 170px !important;
      }
    }
  }
}

.public-details {
  padding-right: 0;
  .details {
    padding: 0;
  }
}

.m-left40 {
  margin-left: -41px !important;
}

.m-left20 {
  margin-left: -22px !important;
}
