.your-info {
  h2 {
    font-size: 20px;
  }
  .info-forms {
    form {
      padding: 10px !important;
    }
  }
  .phone-num {
    font-size: 12px;
    padding-bottom: 0;
    width: inherit;
  }
  .details-labels {
    margin-top: 0 !important;
  }
  .current-address {
    padding-top: 0 !important;
    label {
      font-size: 10px;
    }
  }
  .preferred-languages {
    label {
      font-size: 12px;
    }
    p {
      text-align: left !important;
      padding-left: 0;
      &:first-child {
        margin-top: 15px;
      }
    }
  }
}

.languages, .hours {
  padding-left: 0 !important;
}

.save-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.header {
  padding: 0;
  .header-body {
    padding: 0;
  }
  .mobile-header-menu {
    padding: 0;
  }
  ul {
    li.selected {
      color: $white !important;
    }
  }
}

.header-menu {
  width: 100% !important;
  .bootstrap-select {
    width: 100% !important;
  }
  button, .dropdown-menu {
    border: 0 none !important;
    box-shadow: none !important;
    text-align: center !important;
    font-size: 20px;
    color: $header-menu-item-color;
    span.filter-option {
      text-align: center !important;
    }
  }
  ul {
    li {
      font-size: 20px;
      a {
        display: inline-block !important;
        border-bottom: 1px solid $ff-grey-active;
        padding: 6px 20px 6px 12px;
      }
    }
  }
}

.content {
  padding-bottom: 50px !important;
  .profile-container {
    padding: 0 !important;
  }
}

.footer {
  p {
    margin: 0;
  }
  .footer-content {
    ul {
      li {
        margin-right: 0 !important;
        form {
          margin-top: -16px;
          label {
            padding: 0;
          }
          .bootstrap-select {
            width: 120px !important;
          }
        }
      }
    }
    small {
      padding-bottom: 0 !important;
      padding-top: 20px !important;
    }
  }
}

.equipment-page {
  .special-equipment {
    font-size: 12px !important;
  }
  .other-equipment {
    li {
      font-size: 12px !important;
    }
  }
}

.equipment-content {
  .equipment-dropdowns {
    .form-group {
      margin-bottom: 0 !important;
    }
  }
}

.special {
  padding: 0 48px;
}

.specialties-blocks {
  padding-left: 15px !important;
  &.specialities-textarea {
    padding-left: 0 !important;
  }
  .checkbox {
    margin-top: 0 !important;
  }
  ul {
    li {
      &:first-child {
        text-align: left !important;
        margin-bottom: 15px;
      }
    }
  }
}

.menu-pages {
  .filter-option {
    visibility: hidden;
    color: $white !important;
  }
}

.form-group {
  input {
    @include placeholder($input-color-placeholder);
  }
}

.table-responsive {
  thead {
    tr {
      th {
        padding: 0 15px !important;
      }
    }
  }
}

.rates-description {
  padding: 0 !important;
  margin-top: -115px;
}

.info-forms {
  .invoicing-method {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .invoicing-form {
      margin-top: 0 !important;
    }
    .business-form {
      form {
        margin-top: -20px !important;
      }
    }
    .check-type {
      label {
        &:last-child {
          margin-left: 45px !important;
        }
      }
    }
  }
}

.check-type {
  label {
    padding-left: 20px !important;
  }
}

.method-mobile {
  .method-desc {
    font-size: 10px;
  }
  .short-divider {
    width: 86%;
    display: block;
    margin: 20px 0 20px 30px;
  }
  h6 {
    font-weight: bold;
    margin-top: 25px;
    padding-left: 30px;
  }
  p {
    padding-left: 30px;
  }
  .radio {
    margin: 0;
    label {
      padding-left: 30px !important;
      font-size: 12px;
    }
  }
}

.content {
  .payment-method:not(&.invoicing-method) {
    padding: 20px 35px !important;
  }
}

.modal-send-button {
  padding: 0 !important;
}

.billing-last-step {
  .form-control {
    margin-top: -15px;
  }
  .rates {
    margin-top: 0 !important;
    margin-bottom: 10px;
  }
  .method-mobile {
    p {
      padding-left: 0;
    }
    .method-table-block, .method-table-middle-block {
      padding-bottom: 0;
    }
  }
  .last-step {
    .tooltip {
      top: 395px !important;
    }
  }
}

.view-permit {
  width: 100% !important;
  p {
    text-align: inherit !important;
  }
}

.flight-permit, .insurance {
  label {
    width: 100% !important;
  }
  .slash {
    padding: 0 42px 0 33px;
  }
  .flight-form {
    .bootstrap-select {
      margin-bottom: 10px;
    }
  }
}

.flight-permit {
  .legal-labels {
    padding-left: 30px !important;
  }
}

.text-to-left {
  padding-left: 0 !important;
  text-align: left !important;
}

form.mustermann {
  .bootstrap-select {
    margin-top: 3px !important;
    left: -8px !important;
  }
}

.change-password {
  .save-button {
    float: left;
    margin: 0 !important;
  }
}

.receive-sms {
  .form-group {
    .edit-number {
      margin-top: 15px !important;
    }
  }
}

.info-forms {
  .account {
    padding: 22px !important;
  }
  .enty-type {
    margin-bottom: 15px !important;
    p {
      margin-bottom: 0;
    }
  }
}

.modal {
  .choose-dropdown {
    padding-left: 0 !important;
  }
  .modal-body {
    .form-group {
      label {
        padding-left: 0;
      }
      .dropdown {
        margin-bottom: 10px !important;
      }
    }
    p {
      span {
        margin-top: 5px !important;
        margin-left: 0 !important;
        font-size: 18px !important;
        padding: 3px 18px !important;
      }
    }
    .digit-code {
      text-align: center;
      padding: 0 20px;
      line-height: 30px;
    }
    .get-code {
      text-align: center;
      display: block;
    }
    ul {
      padding-top: 0 !important;
      text-align: center !important;
      button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        float: none !important;
      }
    }
  }
}

.check-emails {
  padding-top: 0;
}

.quotes {
  padding-bottom: 0 !important;
}

.quotes-content {
  .edit {
    font-size: 10px;
  }
  .method-table {
    .method-table-block {
      p {
        padding: 0 !important;
      }
    }
    .create-quote {
      padding-left: 0;
      .images {
        ul {
          padding-left: 15px !important;
        }
      }
    }
    p {
      padding: 24px 0 24px 8px !important;
      margin: 0;
    }
    .p-0 {
      padding: 0 !important;
    }
  }
}

.projects {
  margin-left: 0 !important;
  margin-right: 0 !important;
  ul {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.no-padding-xs {
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.about-pilot {
  padding-top: 15px !important;
  img {

    width: 70px;
    margin: 0 auto;
  }
  .pilot-img {
    margin-left: 46%;
  }
  h1, p {
    text-align: center;
  }
  .certified-pro, .stars {
    margin-right: 0 !important;
  }
}

.open-button {
  .open {
    width: 80px;
    margin: 15px auto !important;
  }
}

.overview-header {
  background: $white;
  .arrow-back {
    padding: 0;
    .back {
      img {
        width: 10px !important;
      }
    }
  }
  h3 {
    padding: 15px 0 !important;
    margin: 0;
    text-align: center;
  }
  .job-overview-header {
    .request-job {
      .form-group {
        height: auto !important;
        button {
          float: none !important;
          @include center-block;
        }
      }
    }
  }
}

.overview-content {
  .m-bot35, .m-bot80 {
    margin-bottom: 0 !important;
  }
  &.base-overview {
    padding-bottom: 15px;
    .client-comments {
      padding-left: 30px !important;
      margin-left: -28px !important;
    }
  }
  h3 {
    padding: 0 !important;
  }
  .images {
    ul {
      padding-left: 15px !important;
    }
  }
}

.no-left-padding-xs {
  padding-left: 0 !important;
}

.profile {
  .arrow-back {
    padding-left: 0;
    .back {
      text-align: left;
    }
  }
  .profile-details {
    .details-form {
      margin-top: 0 !important;
      &.form-upload {
        margin-top: 15px !important;
        padding-left: 0 !important;
        text-align: left !important;
      }
      .form-group {
        margin-bottom: 0 !important;
        margin-top: -5px !important;
        input {
          margin-top: 0 !important;
        }
        &.profile-dropdown {
          .control-label {
            margin-top: 20px;
          }
        }
        &.last-form-group {
          margin-top: 15px !important;
          margin-bottom: 5px !important;
        }
      }
    }
    .profile-avatar {
      height: auto !important;
    }
  }
}

.public-profile {
  padding: 0 !important;
  .public-details {
    padding-right: 0 !important;
    padding-left: 0 !important;
    .details {
      height: auto !important;
      margin-bottom: 20px;
      .details-avatar {
        padding-right: 0;
        float: right;
        img {
          float: right;
          margin-right: -15px;
        }
      }
      .hire {
        li {
          &:first-child {
            img {
              width: 110px;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
      p.address {
        margin-bottom: 0;
      }
      h3 {
        text-align: center;
        padding: 0;
      }
      p {
        &.address, &.more-info {
          text-align: center;
        }
      }
      hr.divider {
        visibility: hidden;
      }
    }
  }
  .public-projects {
    .project {
      width: 47%;
      margin-right: 15px !important;
      &:nth-child(even) {
        margin-right: 0 !important;
      }
    }
  }
}

#carousel-modal {
  .modal-header {
    h4 {
      width: 72%;
    }
    .icons {
      margin-top: -35px !important;
    }
  }
  .modal-dialog {
    width: 290px !important;
  }
  .modal-body {
    padding-left: 0;
    padding-right: 0;
    .carousel-inner {
      width: 100% !important;
    }
    .carousel-control {
      font-size: 20px !important;
      top: -40%;
      &.left {
        left: -24% !important;
      }
    }
    slide.item.active {
      div.item.active {
        height: 340px !important;
      }
    }
  }
  .modal-footer {
    padding: 0;
    .project {
      width: 47% !important;
      float: left;
      &:nth-child(even) {
        margin-right: -15px !important;
      }
    }
  }
}

#share, #delete {
  .modal-action {
    li {
      button {
        margin-top: 20px !important;
      }
    }
  }
}

.projects {
  margin-top: 15px;
}

.activities {
  margin-top: 15px;
  &:first-child {
    margin-left: 0 !important;
  }
  .recent-activities {
    margin-bottom: 15px;
    ul {
      min-height: 245px !important;
      height: auto !important;
    }
  }
}

.funnel-page {
  .steps {
    .panel-group {
      .panel {
        .panel-body {
          ul {
            li {
              width: 50%;
              margin-right: 0 !important;
              img {
                display: block;
                margin-right: auto;
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }
}

.tel-number {
  .tel {
    padding-left: 15px !important;
  }
  ul {
    li {
      width: inherit !important;
      img {
        display: inline-block !important;
      }
    }
  }
}

.funnel-panels {
  .panel-body {
    .container {
      ul {
        width: 100%;
        li {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }
  }
}

.select-package {
  padding: 0 !important;
  label:not(.select-continue) {
    text-align: left !important;
  }
  .inputs, .money {
    padding-left: 0;
  }
  .note {
    small {
      margin-left: 0 !important;
    }
  }
}

.quotes-blocks, .method-table-block {
  &:first-child {
    margin-top: 25px !important;
  }
  &:last-child {
    margin-bottom: 25px !important;
  }
  p {
    margin: 0 !important;
  }
}

.active-jobs, .previous {
  .method-table-block, .quotes-blocks {
    li {
      min-width: 58px;
    }
  }
}

.quotes-content {
  .method-table {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
}

.method-table {
  img {
    width: 10px;
    @include rotate(180deg);
    margin-left: 15px;
    margin-top: 24px;
  }
}

.another-quote, .send-client {
  display: block !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.recent, .view-profile {
  max-width: 100% !important;
}

.packages {
  ul {
    li {
      margin-bottom: 5px;
      border: 0 none !important;
    }
  }
}

#upload-portfolio {
  padding-bottom: 150px;
}

#add-project {
  .upload-window {
    padding: 0;
    .window {
      border-width: 0 0 1px !important;
      margin-top: 0 !important;
    }
  }
  .upload-form {
    padding: 0;
    .form-group {
      padding: 0;
      .own-rights {
        padding-right: 0;
      }
      span {
        b {
          display: inline-block !important;
        }
      }
      .add-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

#contact-us-page {
  .contact-footer {
    .contact-map {
      iframe {
        float: left !important;
      }
    }
  }
}

#jobs-overview-page {
  .docs-form {
    border: 1px solid $current-projects-bg-color;
    padding: 0 7px;
    ul {
      &:last-child {
        border-bottom: 0;
      }
      li {
        display: block;
        margin-right: 0 !important;
        margin-bottom: 14px;
        width: 100% !important;
        line-height: 18px !important;
        float: none !important;
        .choose {
          margin-top: 8px !important;
        }
        small {
          margin-top: 12px;
        }
      }
    }
  }
  .upload-content {
    margin-top: 35px;
    .icons-form {
      padding-top: 0 !important;
      padding-left: 0;
      .choose-file {
        padding-right: 0;
        .choose {
          span {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .names-list {
      padding-left: 15px !important;
    }
  }
}

#about-us-page {
  .about-us-content {
    .our-team {
      padding: 0;
      .team-members {
        padding-left: 0;
        .user-photo {
          .member-info {
            padding: 0;
          }
        }
      }
    }
  }
}

#industry-details {
  .title-style {
    font-size: 30px;
    line-height: 30px;
  }
}

#landing-page {
  .title-style {
    font-size: 30px;
    line-height: 30px;
  }
  .pilot-map-details {
    bottom: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1000;
  }
  .featured-on-container {
    margin-top: 0 !important;
  }

  .partners {
    .partners-list {
      ul {
        li {
          margin-right: 0 !important;
        }
      }
    }
  }
  .why-ff {
    h1 {
      font-size: 20px;
    }
    ul {
      float: left !important;
      text-align: left !important;
      li {
        font-size: 15px !important;
      }
    }
    img {
      float: left !important;
    }
  }
  .landing-content {
    .landing-videos {
      padding: 0 !important;
    }
    .allianz {
      img {
        width: 120px !important;
      }
      h1 {
        font-size: 20px !important;
      }
      p {
        font-size: 10px !important;
      }
    }
    .landing-map {
      margin-top: 20px !important;
    }
    .about-ff, .our-pilots, .engagement {
      margin-top: 0 !important;
      h1 {
        font-size: 20px !important;
      }
      ul {
        li {
          font-size: 10px !important;
          line-height: 14px !important;
        }
      }
    }
    .company-founding {
      .carousel {
        .carousel-indicators {
          bottom: -25% !important;
        }
      }
    }
    .finden-btn, .bewerben-btn {
      float: none !important;
      display: block !important;
      margin: 30px auto 0 !important;
    }
    .bewerben-btn {
      margin-bottom: 30px !important;
    }
    .select-industry {
      h1 {
        font-size: 20px !important;
      }
    }
  }
}

.landing-footer {
  small {
    padding-left: 15px !important;
  }
}

.no-right-padding {
  padding-right: 0 !important;
}

#ff-press-info {
  padding-left: 15px !important;
  .press-center-blocks, .press-aside-blocks {
    ul {
      padding: 30px 5px 30px 8px !important;
    }
  }
}

.login-page {
  padding: 0;
  .email-reset {
    padding: 0;
  }
  .success-block {
    margin-top: 30px !important;
  }
  .logo-xs {
    img {
      width: 99px;
    }
  }
  .login-btn {
    padding: 0;
    ul {
      float: none !important;
      li {
        &:first-child {
          float: left;
          margin-left: 15px;
        }
        &:nth-child(2) {
          float: right;
          margin-bottom: 40px;
          button, a.btn {
            float: right;
          }
        }
      }
    }
  }
  .log-content {
    padding: 0;
    form {
      padding: 0;
      h5 {
        font-size: 12px;
        color: $ff-grey-active;
        margin: 10px 0 33px;
        a {
          text-decoration: underline;
          color: $ff-active-blue;
        }
      }
      label.control-label {
        width: 120px !important;
      }
      input {
        @include placeholder($transparent);
        &.reset-input {
          @include placeholder($public-profile-bg-color);
          &:focus {
            @include placeholder($ff-grey-active);
          }
        }
      }
    }
  }
  .part {
    width: 100% !important;
    margin-top: 0 !important;
    padding: 15px;
    ul {
      li {
        float: right;
        margin-right: 0 !important;
        margin-bottom: 30px;
      }
    }
    small {
      margin-top: 0 !important;
      padding-left: 50px;
    }
  }
  .no-padding {
    padding: 0 !important;
  }
}

#view-details {
  .modal-dialog {
    .modal-content {
      height: 1270px !important;
      padding: 15px !important;
      .carousel-control {
        width: 100% !important;
        span {
          display: block;
          margin-left: auto;
          margin-right: auto;
          &.glyphicon {
            right: 0 !important;
          }
          &.next-offer {
            right: 0 !important;
            top: 100% !important;
            text-align: center;
          }
        }
      }
      .quotes-details {
        margin: 15px 0;
      }
      form {
        button {
          display: block;
          margin-right: auto;
          margin-left: auto;
          margin-bottom: 30px;
        }
      }
    }
  }
}

#offer-carousel {
  .carousel-inner {
    .item {
      .offer-blocks {
        float: none !important;
        display: block;
        margin-left: auto;
        margin-right: auto !important;
        margin-bottom: 40px;
      }
    }
  }
}

#select-offer {
  #offer-modal-dialog {
    width: 290px !important;
    .modal-content {
      height: 570px !important;
    }
  }
}

#booking {
  .modal-dialog {
    width: 300px !important;
    .modal-content {
      height: 670px !important;
      form {
        padding: 15px;
      }
    }
  }
}

#cancel-booking, #booking-conclude {
  .modal-dialog {
    width: 300px !important;
    &:first-child {
      .modal-content {
        height: 150px !important;
      }
    }
    &:last-child {
      .modal-content {
        height: 224px !important;
      }
    }
  }
}

.has-error, .has-success {
  span.form-control-feedback {
    top: 3px !important;
    right: 14px !important;
  }
}

.create-quote.content {
  margin: 0 15px;
}

#discard-changes {
  .modal-dialog {
    .modal-content {
      height: 150px !important;
      .modal-action {
        .save-button {
          margin-top: 20px !important;
        }
      }
    }
  }
}

#equipment-form {
  padding-top: 0 !important;
  padding-bottom: 15px !important;
}

.login-page:not(.signup) {
  .form-group {
    span.form-control-feedback {
      margin-top: -36px !important;
    }
  }
}

.margin-left-xs {
  margin-left: 15px !important;
}

.overview-content {
  h3 {
    margin-bottom: 15px !important;
  }
}

#jobs-overview-page {
  .header-menu {
    button {
      margin: 15px 0;
      .caret {
        right: 25% !important;
      }
    }
  }
}

.xs-padding-left {
  padding-left: 30px !important;
}

.xs-margin-left {
  margin-left: -20px !important;
}

.media-modal {
  .modal-content {
    .confirm-video {
      margin-top: 0;
    }
    label.choose-file {
      padding-left: 15px !important;
    }
  }
}

// Implement alternate labels below the icons
// temporary fix for: https://github.com/angular/material2/issues/8365
.mat-stepper-horizontal {
  padding-left: 8px;
  padding-right: 8px;
}
.mat-horizontal-stepper-header {
  flex-direction: column;
  flex: 1;
  height: 104px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.mat-horizontal-stepper-header .mat-step-icon, 
.mat-horizontal-stepper-header .mat-step-icon-not-touched {
  margin-top: 24px;
  margin-right: 0 !important;
}
.mat-step-label {
  margin-top: 16px;
  width: 100%;
}
.mat-step-text-label {
  text-align: center;
}
.mat-stepper-horizontal-line {
  margin-top: -32px !important;
  max-width: 32px;
}

.visitor-order-funnel-container .package-details-right {
  align-items: center !important;
}

.contact-footer .contact-us-vertical-center {
  display: inline !important;
} 
