#ff-press-info {
  background: #fff;
  .press-title {
    font-family: 'Arquitecta';
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #484848;
    margin: 30px 0;
  }
  .press-aside-blocks, .press-center-blocks {
    .press-content {
      border-width: 0 1px 1px;
      border-style: solid;
      border-color: $ff-grey-active;
      padding: 0;
    }
    h5 {
      padding: 12px;
      margin: 0;
      background: $ff-dark-blue;
      color: $white;
      font-weight: bold;
      font-family: 'Arquitecta';
      letter-spacing: 1px;
      font-size: 20px;
    }
    ul {
      padding: 20px 25px;
      margin: 0;
      li {
        font-size: 12px;
        line-height: 24px;
        a {
          text-decoration: none;
          color: $header-menu-item-color;
          &:hover, &:visited, &:active {
            text-decoration: none;
            color: $header-menu-item-color;
          }
        }
        img {
          width: 10px;
          margin-top: 5px;
          cursor: pointer;
        }
      }
      &.non-media-inquiries {
        border-width: 1px 0;
        border-style: solid;
        border-color: $ff-grey-active;
        li {
          a {
            color: $ff-active-blue;
          }
        }
      }
    }
    .download-kit {
      color: $ff-active-blue;
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
      margin: 20px 20px 0;
    }
  }
  .press-center-blocks {
    ul {
      padding: 30px 20px 48px;
      li {
        line-height: 28px;
      }
    }
    .press-content {
      padding: 5px;
    }
  }
  .font-bold {
    font-weight: bold;
  }
  .press-first-line {
    min-height: 386px;
    margin-bottom: 12px;
  }
  .press-second-line {
    min-height: 246px;
    margin-bottom: 60px;
  }
  .no-padding {
    padding: 0;
  }
  .ff-in-media {
    color: $ff-active-blue;
  }
  .press-kit {
    .list-unstyled {
      border-bottom: 0;
    }
  }
}
.in-media-style {
  padding-left: 0;
  padding-right: 0;
}
