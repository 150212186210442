.overview-header {
  background: $white;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  .job-overview-header {
    height: 110px;
    .ctas {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      height: 110px;
      justify-content: space-between;
      .cta-order-id {
        color: $ff-grey-active;
        font-family: 'Arquitecta';
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .back-arrow {
        color: $ff-grey-active;
      }
      .archive-job {
        display: flex;
        align-items: center;
        height: 90px;
        p {
          font-size: 11px;
          color: $white;
          margin: 0;
        }
      }
      .form-group {
        display: flex;
        padding: 0;
        height: 90px;
        margin: 0;
        align-items: center;
        div {
          width: 100%;
          button {
            height: 31px;
            width: 135px;
            border: 1px solid $white;
            border-radius: 3px;
            text-align: center;
            font-size: 12px;
            color: $white !important;
            background-color: transparent !important;
            &:hover, &:focus {
              background-color: transparent !important;
              border: 1px solid $white !important;
              color: $white !important;
            }
            &.active {
              background-color: $ff-dark-blue !important;
              border-color: $ff-dark-blue !important;
            }
          }
        }
      }
    }
    .nav.nav-tabs {
      position: absolute;
      bottom: 0;
      border-bottom-color: transparent;
      padding-right: 0;
    }
  }
  p {
    font-size: 16px;
  }
  h3 {
    font-size: 24px;
    padding: 0;
    font-family: 'Arquitecta';
    font-weight: bold;
    color: $white;
  }
  .back {
    display: block;
    margin-top: 22px;
    color: $white;
    text-decoration: none;
    font-family: 'Arquitecta';
    &:hover {
      text-decoration: none;
      color: $white;
    }
    img {
      display: inline-block;
      width: 30px;
    }
    span {
      vertical-align: middle;
      font-size: 16px;
    }
  }
  ul {
    li {
      background: $ff-grey-active;
      border-radius: 0;
      font-size: 12px !important;
      a {
        color: $white;
        border: 0 none !important;
        padding: 7px 15px;
        font-size: 13px;
        &:hover, &:visited {
          background: $ff-grey-active;
          border-radius: 0;
          color: $white;
        }
      }
      &.active {
        background: $white;
        a {
          color: $ff-grey-active;
          background: $white;
          border: 0 none !important;
        }
      }
    }
  }
}

#jobs-overview-page {
  .overview-content {
    p {
      font-size: 12px !important;
    }
    h3 {
      padding-top: 21px;
      padding-bottom: 20px;
      font-size: 20px;
      margin-bottom: 0;
      color: $ff-grey-active;
      font-weight: bold;
      font-family: 'Arquitecta';
    }
    .bootstrap-select, .dropdown-menu, .dropdown-toggle {
      width: 150px !important;
    }
    .archive-job {
      padding-left: 0;
      p {
        font-size: 10px;
        color: $header-menu-item-color;
      }
    }
    .address {
      font-weight: normal;
      color: $ff-active-blue;
      text-decoration: underline;
      padding-left: 8px;
    }
    ul {
      li {
        line-height: 25px;
        word-wrap: break-word;
        font-size: 12px;
        &.job-status {
          margin-top: 30px;
        }
        &.bold-list {
          font-weight: bold;
          padding-right: 0;
        }
        .form-group {
          margin-top: 30px;
        }
        .glyphicon-refresh {

          font-size: 15px;
          top: 4px;
          margin-right: 3px;
        }
      }
      &.bold-list {
        font-weight: bold;
        padding-right: 0;
      }
    }
    .site-map {
      background: $site-map-bg-color;
      height: 500px;
      margin-bottom: 15px;
    }
    .client-comments {
      word-wrap: break-word;
      padding: 0;
      margin-left: -35px;
    }
    .site-contact {
      margin-top: 0;
      h3 {
        margin-bottom: 0;
      }
      .ff-comments {
        margin-left: -20px;
      }
      ul {
        margin-bottom: 0;
        &:last-child {
          padding-left: 0;
        }
      }
    }
  }

  .back {
    display: block;
    margin-top: 22px;
    color: $white;
    text-decoration: none;
    font-family: 'Arquitecta';
    &:hover {
      text-decoration: none;
      color: $white;
    }
    img {
      display: inline-block;
      width: 20px;
    }
    span {
      vertical-align: middle;
      font-size: 16px;
    }
  }

  .images {
    margin-top: 7px;
    padding-left: 0;
    ul {
      li {
        margin-right: 10px;
        vertical-align: top;
        width: 55px;
        height: 55px;
        position: relative;
        img {
          max-height: 100%;
        }
      }
    }
    .no-ref-files {
      margin-top: -5px;
      display: block;
      padding-left: 5px;
    }
  }
  .date {
    font-weight: normal;
    font-style: italic;
    color: $canceled-text-color;
    margin-bottom: 0;
  }
  .quote-inquiry {
    padding: 0;
    .user-messages {
      background: $site-map-bg-color;
      .msg-content {
        max-width: 58%;
      }
      .message-to {
        .msg-content {
          background: $white;
          border-color: $white;
        }
        .caret {
          border-top-color: $white !important;
        }
      }
    }
    .message-area {
      background: $white;
    }
  }
  .docs-form {
    margin-top: 60px;
    ul {
      padding: 10px 0;
      border-bottom: 1px solid $current-projects-bg-color;
      li {
        vertical-align: top;
        margin-right: 12px;
        b {
          display: block;
        }
        &:first-child {
          width: 120px;
          word-wrap: break-word;
          padding-right: 0;
        }
        &.ownership {
          width: 315px;
        }
        small {
          display: block;
          font-family: 'Lucida-Grande';
          font-size: 12px;
          color: $light-gray;
          &::before {
            content: ' \25CF';
          }
        }
        .choose-file {
          cursor: pointer;
          span {
            &:first-child {
              width: 110px;
              display: block;
              padding: 3px 15px;
            }
          }
        }
        .choose {
          margin-top: 0;
        }
        button {
          margin: 8px 0 0;
        }
      }
    }
  }
  .upload-content {
    .icons-form {
      padding-top: 38px;
      padding-right: 0;
      padding-bottom: 10px;
      .choose-file {
        margin-bottom: 10px;
        padding-left: 0;
        .choose {
          span {
            display: block;
            width: 110px;
          }
        }
      }
      .upload-btn, .refresh-btn {
        font-size: 12px;
        border-radius: 6px;
        color: $ff-grey-active;
      }
      .btn-link {
        color: $ff-grey-active;
        padding-left: 14px;
        padding-right: 14px;
      }
      .icons-list {
        p {
          cursor: pointer;
          img {
            width: 20px;
          }
        }
      }
    }
    .names-list {
      padding-left: 30px;
      ul {
        padding: 5px;
        &.list-title {
          margin-bottom: 0;
          li {
            color: $ff-grey-active;
          }
        }
        &:not(.list-title) {
          background: $light-gray;
          color: $white;
        }
        &.active {
          background: $ff-active-blue;
        }
        &:last-child {
          margin-bottom: 80px;
        }
        a {
          color: white;
        }
        a:not(:last-child) {
          padding-right: 5px;
        }
      }
    }
  }
}

.icons {
  a:first-child {
    padding-right: 5px;
  }
}

.location-distance {
  padding: 0;
  font-size: 12px;
  margin-top: 5px;
}

.header-menu {
  button.header-menu-button {
    &:focus {
      outline: none !important;
    }
  }
  .job-details-menu {
    li {
      &.active {
        a {
          background-color: $transparent !important;
          color: $ff-grey-active !important;
        }
      }
    }
  }
}

.m-bot35 {
  margin-bottom: 10px;
}

.m-bot12 {
  margin-bottom: 10px;
}

.m-bot80 {
  margin-bottom: 10px;
}

.weight-bold {
  font-weight: bold;
}

.jobs-list-menu {
  position: absolute;
}
