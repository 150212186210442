.client-page-header,
.pilot-page-header {
  background: $ff-dark-blue;
  width: 100%;
  .header-logo {
    img {
      margin: 8px 0;
    }
  }
  ul {
    margin: 0;
    li {
      padding-top: 10px;
      padding-bottom: 10px;
      &.logout {
        padding-top: 18px;
        padding-bottom: 18px;
      }
      .lang-button {
        color: white;
      }
      &:first-child {
        padding-left: 0 !important;
      }
      &:not(:first-child) {
        padding-left: 20px !important;
      }
      padding-right: 0 !important;
      a {
        color: #ffffff;
        font-family: 'Open Sans';
        font-size: 12px;
        text-align: center;
        text-decoration: none;
        border-bottom-width: 0;
      }
      &.point {
        a {
          &.settings {
            background: $ff-orange;
            border-radius: 50%;
            border: 1px solid transparent;
            display: inline-block;
            font-size: 12px;
            height: 25px;
            padding: 0;
            text-align: center;
            vertical-align: middle;
            width: 25px;
            span {
              vertical-align: sub;
            }
          }
        }
      }
    }
  }
}

.menu {
  background: $ff-dark-blue;
  .right-menu {
    padding-top: 0;
  }
  .help {
    background-color: $ff-active-blue;
    margin-right: 20px;
    padding-top: 0;
    &:hover {
      color: white;
    }
  }
  ul {
    li {
      a {
        text-decoration: none;
        color: $white;
        font-size: 12px;
      }
    }
  }
}

.account-update {
  background-color: $active-span-bg-color;
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  ul {
    position: relative;
    li {
      &.yes-icon {
        vertical-align: sub;
        width: 100%;
        img {
          width: 20px;
          height: 20px;
          margin-right: 35px;
          margin-top: 3px;
        }
        span {
          color: $white;
          vertical-align: sub;
        }
      }
      &.close-saving {
        position: absolute;
        top: 7px;
        right: 48px;
      }
      a {
        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
}

#discard-changes {
  .modal-action {
    width: 190px !important;
    button {
      width: 80px;
      padding: 6px !important;
    }
  }
}

.content {
  padding-bottom: 50px;
  .content-menu {
    padding-top: 30px;
    li {
      padding: 0 0 20px;
      a {
        text-decoration: none;
        color: $content-items-color;
        font-size: 12px;
        &:hover, &.active {
          color: $ff-active-blue;
        }
      }
    }
  }
  .your-info {
    h2 {
      color: $ff-grey-active;
      background: $white;
      border-bottom: 1px solid $ff-grey-active;
      padding: 8px 20px;
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 1px;
      font-family: 'Arquitecta';
    }
    .info-forms {
      margin-top: 30px;
      border: 1px solid $ff-grey-active;
      form, .form-horizontal {
        padding: 20px;
        h6 {
          padding: 0 0 10px;
        }
        .dropdown {
          button {
            font-size: 12px;
            text-align: left;
            border: 1px solid $ff-grey-active;
            &:hover {
              background: none;
            }
          }
        }
      }
      .modal {
        .form-horizontal, form {
          padding: 0 25px;
        }
        .control-label {
          text-align: left;
        }
      }
    }
  }
  .languages {
    margin-bottom: 20px;
    font-size: 12px;
    p {
      padding-right: 40px;
    }
    label {
      margin-right: 25px;
    }
  }
  .hours {
    font-size: 12px;
    p {
      padding-right: 40px;
    }
    label {
      font-weight: normal;
      margin-top: 0;
    }
    .save-button {
      background: $ff-orange;
      border: 1px solid $ff-orange;
      color: $white;
      padding: 6px 20px;
      font-size: 12px;
      margin-top: 15px;
      margin-bottom: 15px;
      &:focus, &:visited, &:hover, &:active {
        background: $ff-orange;
        border: 1px solid $ff-orange !important;
        color: $white;
        padding: 6px 20px !important;
        font-size: 12px;
      }
    }
  }
}

.personal-form {
  padding: 15px;
}

.preferred-languages {
  margin-top: 30px;
}

.modal {
  .modal-content {
    border-radius: 0 !important;
    padding: 0;
    box-shadow: none !important;
    border: 1px solid $ff-grey-active !important;
  }
  .modal-body {
    .form-group {
      .dropdown {
        margin-bottom: 30px;
        button {
          text-align: left;
          border: 1px solid $ff-grey-active;
          &:hover {
            background: none;
          }
        }
      }
      input {
        border-radius: 0;
        border: 0.5px solid $ff-grey-active;
        &.checkbox-input {
          border: 0.5px solid $ff-grey-active;
          border-radius: 3px;
        }
      }
      label {
        font-weight: normal;
        margin-top: 5px;
        font-size: 12px;
      }
    }
    .why-verify {
      color: $alert-danger-text;
      text-decoration: none;
      &:hover {
        color: $alert-danger-text;
        text-decoration: none;
      }
    }
    p {
      margin-bottom: 10px;
      font-size: 12px;
      margin-top: 10px;
      span {
        border: 1px solid $ff-grey-active;
        padding: 7px 18px;
        margin-left: 16px;
        font-weight: bold;
      }
    }
    .digit-input {
      width: 100px !important;
      display: inline-block !important;
      font-size: 18px;
      font-weight: bold;
      margin-left: 12px;
    }
    ul {
      padding-bottom: 20px;
      padding-top: 20px;
      .verify-code {
        button {
          color: $white;
          padding: 5px 16px;
          text-decoration: none;
          font-size: 12px;
          display: inline-block;
          background: $ff-active-blue;
          border: 1px solid transparent;
          border-radius: 6px;
          margin-top: 15px;
        }
      }
      .verify {
        background: $ff-active-blue;
        border: 1px solid transparent;
        border-radius: 6px;
        button {
          color: $white;
          padding: 5px 20px;
          text-decoration: none;
          background-color: transparent !important;
          border: none !important;
          font-size: 12px;
          display: block;
        }
      }

      .cancel-verify {
        button {
          background: none;
          border: 0 none;
          color: $ff-grey-active;
          font-size: 12px;
        }
      }
    }
    .get-code {
      text-decoration: underline;
      color: $ff-active-blue;
      font-size: 12px;
      background-color: transparent !important;
      border: none !important;
      &:hover {
        color: $alert-danger-text;
        text-decoration: underline;
      }
    }
    .code {
      text-decoration: none;
      color: $ff-active-blue;
      padding-top: 6px;
      &:hover {
        color: $alert-danger-text;
        text-decoration: underline;
      }
    }
  }
}

.footer {
  // background-image: $ff-gradient;
  
  position: relative;
  font-size: 12px;
  .footer-logo {
    cursor: pointer;
    padding-bottom: 15px;
  }
  .footer-content {
    p, small {
      color: $ff-grey;
      margin-right: 35px;
      margin-top: 0;
    }
    ul {
      li {
        vertical-align: top;
        margin-right: 35px;
        margin-bottom: 10px;
        a {
          text-decoration: none;
          color: $ff-grey;
          font-family: 'Open Sans';
          font-size: 14px;
          line-height: 19px;
          &:hover {
            color: $ff-active-blue;
            text-decoration: none;
          }
        }
      }
    }
    .choose-language {
      label {
        color: $white;
        padding-left: 0;
        padding-bottom: 0;
        font-size: 12px;
      }
      .chosen-language, .dropup {
        display: block;
        button {
          border: 1px solid $white;
          border-radius: 3px;
          color: $white;
          &:focus {
            outline: none !important;
          }
          .caret {
            margin-top: -4px;
            border-top-width: 9px;
          }
        }
        .dropdown-menu {
          background-color: $ff-grey-active !important;
          color: $white;
          &.open {
            border-radius: 0 0 3px 3px;
            border-color: $white !important;
            ul {
              li {
                a {
                  color: $white;
                }
              }
            }
          }
          ul {
            li {
              background: transparent !important;
              a {
                &:hover, &:active, &:focus {
                  background-color: transparent !important;
                  outline: none !important;
                }
              }
            }
          }
        }
      }
    }
    small {
      padding-bottom: 20px;
    }
  }
}

.details-labels {
  margin-top: 8px !important;
}

.equipment-dropdowns {
  .form-group {
    margin-bottom: 7px !important;
  }
}
