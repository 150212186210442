.quotes {
  .content-menu {
    padding-top: 55px;
  }
}

.quotes-content {
  padding-top: 57px;
  padding-bottom: 20px;
  h4 {
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: 900;
    line-height: 13px;
    font-family: 'Arquitecta';
    color: #484848;
    margin-top: 0;
    margin-bottom: 30px;
  }
  p {
    font-size: 14px;
  }
  .method-table {
    margin: 0 !important;
    .table-responsive {
      border: 0 none !important;
      .table {
        border-collapse: separate;
        border-spacing: 0 4px;
        thead {
          tr {
            th {
              border: 0 none !important;
              padding: 15px;
              font-size: 12px;
              vertical-align: top;
            }
          }
        }
        tbody {
          tr {
            background: #fff;
            color: $ff-grey;
            td {
              font-size: 12px;
              padding: 15px;
              // border: 0 none !important;
              vertical-align: middle;
              white-space: pre-line;
            }
          }
        }
      }
    }
    p {
      padding: 24px 0;
      margin-bottom: 0;
      font-size: 12px;
    }
    .create-btn {
      background-color: $ff-dark-blue;
      border-radius: 6px;
      border: 1px solid $ff-dark-blue;
      color: #fff;
      font-size: 12px;
      padding-left: 15px;
      padding-right: 15px;
      text-align: center;
      text-decoration: none;
      &:hover, &:visited, &:active {
        background-color: $ff-dark-blue;
        color: #fff;
        text-decoration: none;
      }

      &.disabled {
        @include opacity(0.5);
      }
    }
  }
  .quotes-blocks {
    background-color: $ff-grey-active;
    color: $white;
  }
}

.method-table-block, .quotes-blocks {
  display: table;
  li {
    display: table-cell;
  }
}

.no-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.method-table-block {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  li {
    p {
      padding-bottom: 0 !important;
    }
  }
}

.active-jobs, .quotes-content {
  .method-table, .method-table-block {
    .date {
      padding-left: 15px !important;
    }
    p {
      padding: 24px 0;
      margin-bottom: 0 !important;
      &.completed {
        color: $active-span-bg-color;
      }
      &.cancelled {
        color: $canceled-text-color;
      }
    }
  }
}
