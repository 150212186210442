.form-horizontal .control-label {
  text-align: left;
}
.preferred-languages {
  p {
    text-align: left !important;
    padding-left: 0;
    &:first-child {
      margin-top: 15px;
    }
  }
  .save-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer {
  p {
    margin: 0;
  }
  .footer-content {
    ul {
      li {
        margin-right: 20px !important;
      }
    }
  }
}

.special {
  float: right !important;
  padding: 0 15px !important;
}

.equipment-dropdowns {
  padding: 0 0 40px !important;

  label {
    &:first-child {
      margin-top: 0 !important;
    }
  }
}

.billing-last-step {
  .tooltip {
    top: 310px !important;
  }
}

.equipment {
  .special-equipment, .other-equipment {
    margin-left: 25px !important;
  }
}

.specializations {
  .checkbox {
    padding: 0 !important;
  }
  .checkbox-row {
    padding: 0 !important;
  }
}

.edit-number {
  padding-left: 0 !important;
  margin-top: 15px !important;
  .edit {
    font-size: 10px;
  }
}

.billing-last-step {
  .info-forms {
    form {
      &.last-step {
        padding: 0 !important;
      }
    }
  }
  .last-step {
    .tooltip {
      top: 345px !important;
    }
  }
}

.mustermann {
  .full-access {
    .btn-group {
      &.bootstrap-select {
        width: inherit !important;
        margin-left: 0 !important;
      }
    }
  }
}

.no-right-padding {
  padding-right: 0 !important;
}

.quotes-content {
  padding-right: 0 !important;
  padding-left: 0 !important;
  .method-table-block {
    p {
      padding: 24px 0 24px 7px !important;
    }
  }
  .p-0 {
    padding: 0 !important;
  }
  .create-quote {
    padding: 0 4px 0 0 !important;
  }
  .method-table-block, .quotes-blocks {
    li {
      min-width: 80px !important;
      p {
        margin-right: 12px;
        &.mapping {
          margin-right: 12px;
        }
      }
    }
  }
}

.content-menu {
  padding-right: 0;
}

.previous, .active-jobs {
  .method-table-block, .quotes-blocks {
    li {
      min-width: 50px !important;
    }
  }
}

.projects {
  margin-left: 0 !important;
  margin-right: 0 !important;
  .open-button {
    padding: 0 !important;
  }
}

.activities {
  .recent-activities {
    .edit {
      padding-top: 15px !important;
    }
  }
}

.overview-header {
  ul {
    li {
      border-right: 3px solid $ff-grey-active;
      padding: 0;
      a {
        padding: 7px 0 !important;
      }
    }
  }
}

.profile-details {
  .profile-avatar {
    height: auto !important;
  }
}

.public-profile {
  .public-projects {
    .project {
      margin-right: 15px !important;
      &:nth-child(4n - 0) {
        margin-right: 0 !important;
      }
    }
  }
}

#carousel-modal {
  .modal-dialog {
    width: 550px !important;
  }
  .modal-body {
    padding-left: 0;
    padding-right: 0;
    .carousel-inner {
      width: 100% !important;
    }
  }
  .modal-footer {
    .project {
      width: 21% !important;
      margin-right: 24px !important;
      float: left;
      &:last-child {
        margin-right: -15px !important;
      }
    }
  }
}

.activities {
  &:first-child {
    margin-left: 0 !important;
  }
}

.note {
  small {
    margin-left: 0 !important;
  }
}

#jobs-overview-page {
  .docs-form {
    border: 1px solid $current-projects-bg-color;
    ul {
      &:last-child {
        border-bottom: 0;
      }
      li {
        display: block;
        margin-right: 0 !important;
        margin-bottom: 14px;
        width: 100% !important;
        line-height: 18px !important;
        float: none !important;
        .choose {
          margin-top: 8px !important;
        }
      }
    }
  }
}

.client-login {
  h3 {
    margin-top: 0 !important;
  }
}

.public-details {
  padding-right: 0;
  .details {
    padding: 0;
  }
}

.table-responsive {
  table {
    tbody {
      tr {
        td {
          &:last-child {
            padding: 24px 24px 24px 10px !important;
          }
          &.open-request {
            padding: 24px 5px 24px 0 !important;
          }
        }
      }
    }
  }
}

.m-left40 {
  margin-left: -41px !important;
}

.m-left20 {
  margin-left: -22px !important;
}
