body, html {
  overflow-x: hidden;
  font-family: 'Open Sans';
  padding-right: 0 !important;
  margin: 0 auto;
  background-color: #ffffff;
}

body {
  overflow-y: hidden;
  background-color: #ffffff;
}

body.modal-open, body.modal-close {
  padding-right: 0 !important;
  overflow: hidden !important;
  position: fixed;
  width: 100%;
}

.modal.fade.in {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

button, select, option {
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

.custom-selectbox {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.dropdown-menu.inner {
  li {
    a {
      background-color: $transparent !important;
      &:focus {
        outline: none !important;
      }
      &:hover {
        background-color: $transparent !important;
      }
    }
  }
}

button {
  &.toggle-disable {
    background: transparent !important;
    border-color: $public-profile-bg-color !important;
    border-radius: 3px;
    display: block;
    font-size: 12px;
    &:not([disabled]), &.active {
      background: $ff-active-blue !important;
      color: $white !important;
      border-color: $ff-active-blue !important;
      padding: 6px 24px;
      font-size: 12px;
    }
    &.saved {
      color: $ff-active-blue !important;
      background: $white !important;
    }
    &.autocomplete-button {
      width: auto !important;
    }
    &.open-map-button {
      display: inline-block;
      margin-top: 10px;
      border-radius: 6px;
      background-color: $ff-active-blue;
      font-size: 14px;
      font-weight: bold;
      line-height: 13px;
      text-align: center;
      height: 40px;
      width: 203px;
      border: 2px solid $ff-active-blue;
    }
  }
}

.form-group {
  input {
    @include placeholder($transparent);
    &:focus {
      box-shadow: none !important;
      outline: none !important;
      border-color: $ff-grey-active !important;
    }
  }
}

.form-control[disabled] {
  background-color: transparent !important;
}

.specialties-blocks {
  input {
    @include placeholder($ff-grey-active);
  }
}

a[type='button'] {
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

.btn-default:active, 
.btn-default.active, 
.open .btn-default.dropdown-toggle {
  background-color: transparent !important;
}

.btn.dropdown-toggle {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.dropdown {
  button {
    font-size: 12px;
  }
}

.active-btn {
  font-size: 18px;
  opacity: 1 !important;
  color: $white;
  text-decoration: none !important;
  &:hover {
    color: $white;
  }
}

button.dropdown-toggle {
  background-color: transparent;
  border-color: $ff-grey-active;
  &:hover, &:active, &:focus {
    background-color: transparent;
    border-color: $ff-grey-active;
  }
  border-radius: 6px;
  span {
    &.caret {
      margin-top: 5px;
      @include transition(all 1s ease);
    }
  }
}

.btn-default {
  &:focus {
    background-color: transparent !important;
  }
}

.dropdown-menu {
  margin-top: 0;
  border-radius: 0 0 6px 6px;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: $ff-grey-active;
  min-width: 150px;
  &.open {
    max-width: 220px !important;
  }
  li {
    font-size: 12px;
    &:focus, &:active, &.active {
      outline: 0 !important;

      a {
        color: $ff-grey-active !important;
      }
    }
    a {
      padding: 3px 20px 3px 12px;
      color: $ff-grey-active;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.form-group {
  label {
    font-weight: normal;
    font-size: 12px;
  }
  input {
    border-radius: 0;
    border: 1px solid $ff-grey-active;
    font-size: 12px;
    &.checkbox-input {
      border: 1px solid $ff-grey-active;
      border-radius: 3px;
    }
  }
}

.checkbox label {
  padding-left: 0 !important;
  font-size: 12px;
  input[type=checkbox] {
    display: none;
    &:checked + span::before {
      content: '\2714';
      position: absolute;
      font-weight: bold;
      top: -9px;
      left: 1px;
      font-size: 17px;
      color: $ff-active-blue;
    }
  }
  span.checkboxes {
    vertical-align: middle;
    height: 14px;
    width: 14px;
    border: 1px solid $ff-grey-active;
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }
}

.radio {
  padding-left: 20px;
  label {
    padding-left: 0 !important;
  }
  input[type='radio'] {
    margin-top: 2px;
  }
}

.radio-inline {
  font-size: 12px;
  input[type='radio'] {
    margin-top: 2px;
  }
}

select {
  height: 35px;
  width: 240px;
  border-radius: 6px;
  border: 1px solid $ff-grey-active;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.divider {
  width: 100%;
  display: inline-block;
  margin: 10px 0;
}

.save-button {
  background: $ff-active-blue;
  color: $white;
  margin-bottom: 25px;
  border-color: $ff-active-blue;
  margin-top: 25px;
  padding: 6px 24px;
  font-size: 14px;
  font-weight: bold;
  &:hover, &:focus, &:visited, &:active {
    background: $ff-active-blue !important;
    border-color: $ff-active-blue !important;
    color: $white !important;
    font-size: 14px !important;
  }
}

.display-none {
  display: none !important;
}

.inline-block {
  display: inline-block !important;
}

label {
  &.control-label {
    padding-top: 0 !important;
    width: 160px;
  }
}

.rotate {
  @include rotate(180deg);
}

.next {
  background: $ff-active-blue;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 12px;
  a {
    color: $white;
    padding: 5px 20px;
    text-decoration: none;
    display: block;
  }
}

.cancel {
  font-size: 12px;
  a {
    text-decoration: none;
    color: $ff-grey-active;
    &:hover {
      text-decoration: none;
      color: $ff-grey-active;
    }
  }
}

.dropup {
  .caret {
    border-bottom-width: 9px !important;
  }
}

.caret {
  margin-top: -4px !important;
  border-top-width: 9px !important;
}

.no-decoration {
  text-decoration: none !important;
}

.use-address {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  a {
    color: $ff-grey-active !important;
    text-decoration: none !important;
  }
}

.bootstrap-select.dropup {
  div.dropdown-menu {
    border-width: 1px 1px 0 !important;
    border-style: solid !important;
    border-radius: 6px 6px 0 0 !important;
    border-color: $ff-grey-active !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
  }
}

.dropdown-select {
  border-bottom-color: transparent !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.dropup-select {
  border-top-color: transparent !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: none !important;
}

.header-logo, .footer-logo {
  & img {
    height: 40px;
  }
}

.hide-elem {
  visibility: hidden !important;
}

.full button span {
  background-color: limegreen;
  border-radius: 32px;
  color: black;
}

.partially button span {
  background-color: orange;
  border-radius: 32px;
  color: black;
}

.clear {
  clear: both;
}

.validation-msg-wrapper {
  clear: both;
  .validation-error {
    font-size: 16px;
    color: $alert-danger-text;
    &.rel {
      position: relative !important;
    }
  }
}

.pointer {
  cursor: pointer !important;
}

.pilot-slider {
  .slider {
    .slider-tick-container {
      .slider-tick {
        display: none !important;
      }
    }

    .slider-handle {
      padding: 0 0 0 4.5px !important;
      font-size: 11px !important;
    }
  }
}

.btn-active {
  background-color: $ff-active-blue !important;
  border-color: $ff-active-blue !important;
  color: $white !important;
}

.character-counter {
  float: right;
  font-size: 10px;
  color: $calendar-color;
  font-weight: bold;
}

.display-none {
  display: none;
}

.has-success {
  label {
    color: $ff-grey-active !important;
  }
  &.col-sm-6, &.col-sm-4 {
    .form-control-feedback {
      top: 26px !important;
      right: 16px !important;
    }
  }
  .form-control-feedback {
    top: 30px !important;
    right: 20px !important;
    color: $success-color !important;
  }
  .form-control, .form-control:focus {
    border-color: $ff-grey-active !important;
  }
  span {
    display: block !important;
  }
  .error-icon {
    display: none !important;
  }
  .btn-group {
    button.dropdown-toggle {
      border-color: $ff-grey-active !important;
    }
    span.form-control-feedback {
      top: 3px !important;
      right: 3px !important;
    }
  }
  .insurance-group, .inputs {
    span.form-control-feedback {
      top: 3px !important;
      right: 14px !important;
    }
  }
  &.order-group {
    span.form-control-feedback {
      right: 0 !important;
    }
  }
}

.has-error {
  label {
    color: $ff-grey-active !important;
  }
  &.checkbox {
    .checkboxes {
      display: inline-block !important;
      border-color: $error-color !important;
    }
  }
  &.radio-inline {
    .radios {
      display: inline-block !important;
      border-color: $error-color !important;
    }
  }
  &.order-group {
    span.form-control-feedback {
      right: 0 !important;
    }
  }
  &.col-sm-6, &.col-sm-4 {
    .form-control-feedback {
      top: 26px !important;
      right: 16px !important;
    }
  }
  .form-control-feedback {
    top: 30px !important;
    right: 20px !important;
    color: $error-color !important;
  }
  .form-control, .form-control:focus {
    border-color: $error-color !important;
  }
  span {
    display: block !important;
  }
  .success-icon {
    display: none !important;
  }
  .custom-selectbox {
    button.btn.dropdown-toggle {
      border-color: $error-color !important;
      .caret {
        color: $error-color !important;
      }
    }
  }
  .btn-group {
    button.dropdown-toggle {
      border-color: $ff-grey-active !important;
    }
    span.form-control-feedback {
      top: 3px !important;
      right: 3px !important;
    }
  }
  .insurance-group, .inputs {
    span.form-control-feedback {
      top: 3px !important;
      right: 14px !important;
    }
  }
}

.textarea-error {
  border-color: $error-color !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: $white !important;
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  -webkit-text-fill-color: #333;

}

.error-messages {
  margin-bottom: 0;
  span {
    font-size: 12px;
    color: $error-color;
  }
}

.login-page {
  .form-group {
    span.form-control-feedback {
      position: inherit !important;
      float: right;
      margin-top: -64px;
      margin-right: -18px;
    }
  }
}

.no-m-bottom {
  margin-bottom: 0 !important;
}

.m-bot15 {
  margin-bottom: 15px !important;
}

.pac-container {
  z-index: 1050;
}

.loading-wrapper {
  background: $transparent-alpha;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
@-moz-keyframes rotate {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes rotate {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes rotate {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}
.loading {
  animation: rotate 2000ms linear infinite;
  height: 100px !important;
  width: 100px !important;
  z-index: 99999999999;
}

.relative {
  position: relative !important;
  .loading-wrapper {
    position: absolute;
  }
}

.main-landing-container {
  width: 100%;
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
}
.modal-backdrop {
  opacity: 0.8 !important;
}
.mat-step-icon {
  background-color: $ff-grey-active !important;
}

.google-tag-iframe {
  display: none;
  height: 0;
  visibility: hidden;
  width: 0;
}

.maintenance-message {
  margin: 8px;
  text-align: center;
}

.mat-form-field {
  width: 100%;
}

.background {
  background: $ff-background-active;
}

.profile-3 {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100px;
  margin-top: 20px;
}
