.create {
  padding: 0;
}

.create-quote {
  .selected-pilot {
    margin: 22px 0;
    padding: 0;
    h4 {
      background: $ff-active-blue;
      color: $ff-grey-active;
      margin: 0;
      padding: 12px;
      font-weight: bold;
      font-size: 14px;
      &:first-child {
        border-radius: 3px 3px 0 0;
      }
      &:last-child {
        border-radius: 0 0 3px 3px;
      }
    }
    .pilot-rating-xs {
      background: $ff-active-blue;
      border-radius: 0 0 3px 3px;
      padding: 20px 15px;
      ul {
        li {
          padding: 3px 0;
          span {
            color: $white;
          }
        }
      }
    }
    ul {
      margin-bottom: 0;
      padding: 0;
      li {
        &:last-child {
          .details-arrow {
            @include rotate(180deg);
          }
        }
        span {
          &.hired-pilot {
            padding: 2px 22px 6px;
            background: $ff-grey-active;
            color: $ff-grey-active;
            &:last-child {
              margin-right: -11px;
              padding: 2px 14px 6px;
            }
          }
          &.pilot-rating {
            padding: 3px 14px 5px 6px;
            font-weight: bold;
            color: $ff-grey-active;
            border-right: 0.22em solid $ff-active-blue;
          }
          &.pilot-price {
            font-weight: bold;
            margin-left: 14px;
            color: $ff-grey-active;
          }
        }
        .details-arrow {
          display: inline-block;
          width: 0;
          vertical-align: middle;
          height: 0;
          margin-left: -4px;
          border-left: 20px solid $ff-grey-active;
          border-top: 14px solid transparent;
          border-bottom: 14px solid transparent;
          background: none;
        }
      }
    }
  }

  .images {
    padding-left: 0;
    ul {
      li {
        margin-right: 10px;
        vertical-align: top;
        width: 55px;
        height: 55px;
        position: relative;
        img {
          max-height: 100%;
        }
      }
    }
  }
  .offer-title {
    span {
      &:first-child {
        font-size: 20px;
        font-family: 'Arquitecta';
        font-weight: bold;
        color: $ff-grey-active;
        margin-right: 15px;
      }
      &:last-child {
        font-size: 12px;
        color: $ff-grey-active;
      }
    }
  }
  .select-pilot {
    p {
      font-size: 12px;
      color: $header-menu-item-color;
      margin-bottom: 0;
      a {
        color: $ff-active-blue;
        text-decoration: underline;
      }
    }
  }
  #offer-carousel {
    margin-top: 45px;
    .item {
      &.next, &.left {
        background-color: $transparent !important;
      }
    }
    .carousel-control {
      background-image: none !important;
    }
    .offer-blocks {
      width: 260px;
      float: left;
      margin-top: 20px;
      margin-right: 25px;
      .offer {
        border: 1px solid $header-menu-item-color;
        border-radius: 3px;
        img {
          width: 70px;
          margin-top: 5px;
        }
        span.avatar {
          width: 70px;
          border-radius: 50%;
          background-size: cover;
          overflow: hidden;
          position: relative;
          height: 70px;
          display: block;
          margin: 5px auto;
        }
        p {
          margin-bottom: 0;
          font-size: 12px;
          color: $header-menu-item-color;
          a {
            text-decoration: underline;
            color: $ff-active-blue;
          }
        }
        .work-rate {
          margin-top: 5px;
          padding: 5px 0;
          background: $finden-btn-bg-color;
          img {
            width: 120px;
            margin-bottom: 7px;
          }
        }
      }
      .view-details {
        margin-top: 5px;
        border: 1px solid $header-menu-item-color;
        border-radius: 3px;
        ul {
          margin-left: 0;
          margin-bottom: 0;
          @include transition(all, 1s, ease);
          cursor: pointer;
          li {
            padding: 0;
            a {
              color: $ff-active-blue;
              text-decoration: underline;
              font-size: 10px;
              vertical-align: sub;
              padding-right: 15px;
              text-align: right;
              &:focus {
                outline: none !important;
              }
            }
            &:first-child {
              span {
                font-weight: bold;
                background: $ff-active-blue;
                color: $ff-grey-active;
                font-size: 20px;
                padding: 0 12px;
                &.details-arrow {
                  display: inline-block;
                  width: 0;
                  vertical-align: bottom;
                  height: 0;
                  margin-left: -4px;
                  border-left: 9px solid $ff-active-blue;
                  border-top: 14px solid transparent;
                  border-bottom: 14px solid transparent;
                  background: none;
                }
              }
            }
            &:nth-child(2) {
              margin-left: -12px;
              span {
                color: $ff-grey-active;
                font-size: 20px;
                font-weight: bold;
              }
            }
          }
          &:hover {
            background-color: $ff-active-blue;
            li {
              &:nth-child(2), &:last-child {
                span, a {
                  color: $white !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .back {
    display: block;
    margin-top: 22px;
    margin-left: 15px;
    color: $ff-grey-active;
    text-decoration: none;
    font-family: 'Arquitecta';
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 900;
    &:hover {
      text-decoration: none;
    }
    img {
      display: inline-block;
      width: 30px;
    }
    span {
      vertical-align: middle;
      font-size: 16px;
    }
  }
  .quote-header {
    border: 1px solid $ff-grey-active;
    background: #fff;
    h3 {
      color: $ff-grey-active;
      margin-top: 15px;
      font-size: 20px;
      font-family: 'Arquitecta';
      font-weight: 900;
      letter-spacing: 1px;
    }
    .open-quoting {
      color: $white;
      font-size: 12px;
      margin-bottom: 10px;
      span {
        color: $white;
      }
    }
    .save-button {
      margin-top: 15px;
    }
  }
  .requests {
    border: 1px solid $ff-grey-active;
    .view-request {
      display: block;
      font-size: 12px;
      color: $white;
      border: 0 none;
      padding-left: 0;
      margin-left: 15px;
      background: transparent;
      .caret {
        @include transition(all 1s ease);
      }
    }
    .collapse, .collapsing {
      position: relative;
      padding: 0;
      z-index: 100;
      background-color: $white;
      > * > .row {
        margin-top: 10px;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 12px;
        color: $ff-grey-active;
        &.address {
          text-decoration: underline;
        }
        &.pre {
          white-space: pre-line;
        }
        a {
          color: $ff-active-blue;
          font-weight: 900;
        }
      }
      .your-quote {
        p {
          color: $ff-active-blue;
          font-size: 12px;
        }
      }
      .site-map {
        background: $site-map-bg-color;
        border: 1px solid $site-map-border-color;
        height: 500px;
        margin-bottom: 15px;
      }
      label {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 4px;
        font-family: 'Open Sans';
        color: $ff-grey-active;
        &.client-comments {
          line-height: 18px;
        }
      }
      ul {
        li {
          font-size: 12px;
          line-height: 24px;
          color: $ff-grey-active;
        }
      }
    }
  }
  .packages {
    margin-top: 0;
    margin-bottom: 2px;
    ul {
      padding-right: 0;
      margin: 0;
      padding-left: 0;
      li {
        background: $current-projects-bg-color;
        vertical-align: top;
        height: 30px;
        border-right: 4px solid $ff-grey-active;
        &:last-child {
          border: 0 none !important;
        }
        &.active {
          height: 35px;
          background: $ff-grey-active;
        }
        p {
          margin: 0;
          cursor: pointer;
          font-size: 12px;
          padding: 7px;
          color: $ff-grey-active;
        }
      }
    }
  }
  .package-line {
    background: $ff-grey-active;
    height: 30px;
    &.active {
      margin-top: -5px;
    }
  }
  .create-qoute-steps {
    border-left: 1px solid $ff-grey-active;
    border-right: 1px solid $ff-grey-active;
    &.comment-box {
      padding-bottom: 20px;
    }
    &.add-packages {
      border-bottom: 1px solid $ff-grey-active;
      button {
        display: inline-block !important;
      }
    }
    &.no-quotes {
      border-bottom: 1px solid $ff-grey-active;
      p {
        font-size: 14px;
        color: $canceled-text-color;
        font-weight: bold;
        padding: 20px 30px 0;
      }
      a {
        display: block;
        padding: 6px !important;
        border-radius: 3px !important;
      }

      .quote-successful {
        p {
          color: $active-span-bg-color;
        }
      }
    }
    .number {
      margin-top: 30px;
      padding: 0;
      span {
        border: 1px solid $ff-grey-active;
        color: $ff-active-blue;
        font-size: 12px;
        padding: 15px 20px;
      }
    }
    label:not(.select-continue) {
      padding: 0;
      margin-top: 7px;
      width: 138px;
    }
    .select-package {
      margin-top: 32px;
      h5 {
        margin-bottom: 20px;
        margin-top: 0;
      }
      .optional {
        font-style: italic;
      }
      p {
        font-size: 12px;
      }
      span.add-team {
        font-size: 12px;
        color: $ff-active-blue;
      }
      div.checkbox {
        label {
          width: 100%;
        }
      }
    }
    .pilot-slider {
      .slider.slider-horizontal {
        .slider-selection {
          height: 1px !important;
          top: 5px !important;
        }
        .slider-selection.tick-slider-selection {
          background-image: none !important;
          background-color: $current-projects-bg-color !important;
        }
        .slider-track {
          background-image: none !important;
          box-shadow: none !important;
          .slider-track-low, .tick-slider-selection, .slider-track-high {
            width: 100% !important;
          }
        }
        .slider-handle {
          width: 15px !important;
          height: 15px !important;
          background-image: none !important;
          top: 3px !important;
          color: $ff-active-blue;
          box-shadow: none !important;
          background-color: $current-projects-bg-color !important;
        }
      }
    }
    .note {
      padding: 0;
      small {
        font-size: 10px;
        margin-left: -5px;
      }
      margin-top: -15px;
    }
    .upload-text {
      color: red;
    }
    textarea {
      resize: none;
      padding-left: 5px;
    }
    p.intro-msg {
      text-decoration: underline;
      color: $ff-active-blue;
      font-size: 12px;
    }
    ul {
      margin-top: 15px;
      &.money {
        margin-top: 0;
      }
      li {
        font-size: 12px;
        line-height: 24px;
        color: $ff-grey-active;
      }
    }
    .another-quote, .send-client {
      border: 1px solid $ff-grey-active;
      font-size: 12px;
      border-radius: 6px;
      margin: 20px 12px;
      padding: 5px 12px;
      min-width: 144px;
      &:hover {
        background-color: transparent !important;
      }
      &:focus, &:active, &:visited {
        border-color: $ff-active-blue;
        background-color: $ff-active-blue !important;
        color: $white;
      }
      &.pass-quote {
        background-color: $canceled-text-color;
        color: $white;
      }
      &.active {
        border-color: $ff-active-blue;
        background-color: $ff-active-blue !important;
        color: $white;
      }
    }
  }
}

#view-details, #select-offer {
  .modal-dialog {
    width: 75% !important;
    .modal-content {
      height: 360px;
      padding: 50px 30px 40px;
      button.close {
        position: absolute;
        top: 0;
        right: 5px;
        font-weight: normal !important;
        opacity: 1 !important;
      }
      .modal-body {
        padding: 0 !important;
        .item {
          &.next, &.left {
            background-color: $transparent !important;
          }
        }
        .carousel-control {
          background-image: none !important;
          width: 17%;
          &.right {
            opacity: 1;
            .glyphicon {
              color: $ff-grey-active;
              opacity: 1;
              text-shadow: none;
              font-size: 42px;
              top: 30%;
              right: -66%;
            }
            .next-offer {
              top: 35%;
              position: relative;
              font-size: 10px;
              color: $ff-grey-active;
              text-shadow: none;
              right: -50px;
            }
          }
        }
        .selected-offer {
          span.avatar {
            width: 40px;
            margin-right: 15px;
            float: left;
            border-radius: 50%;
            background-size: cover;
            overflow: hidden;
            position: relative;
            height: 40px;
          }
          img {
            width: 40px;
            margin-right: 15px;
            float: left;
          }
          ul {
            padding: 0;
            li {
              font-size: 12px;
              line-height: 17px;
              &:first-child {
                color: $ff-grey-active;
              }
              &:last-child:not(.pilot-rating) {
                color: $ff-active-blue;
              }
              img {
                width: 65px;
                margin-right: 0;
              }
              &.title {
                font-family: 'Arquitecta';
                font-size: 20px;
                font-weight: bold;
                color: $ff-grey-active;
              }
            }
          }
          p {
            font-size: 12px;
            margin: 0;
            &.pilot-comments {
              margin-top: 15px;
            }
          }
          button:not(.save-button) {
            font-size: 14px;
            color: $ff-active-blue;
            font-weight: bold;
            width: 144px;
            font-family: 'Arquitecta';
            border-color: $ff-active-blue;
            border-radius: 3px;
            background: $transparent !important;
            &:focus, &:hover {
              border-color: $ff-active-blue !important;
              background: $transparent !important;
              color: $ff-active-blue !important;
            }
          }
        }
        .quotes-details {
          border-radius: 3px;
          border: 1px solid $ff-grey-active;
          width: 100%;
          h1 {
            margin: 0;
            font-size: 24px;
            color: $header-menu-item-color;
            font-weight: bold;
          }
          h2 {
            font-size: 20px;
            margin: 7px 0;
            font-weight: bold;
            color: $header-menu-item-color;
          }
          ul {
            margin-top: 4px;
            margin-bottom: 0;
            background: $finden-btn-bg-color;
            padding: 2px;
            li {
              font-size: 12px;
              color: $ff-grey-active;
              &:not(:first-child):not(:last-child) {
                border-bottom: 1px solid $ff-grey-active;
              }
              span {
                &:last-child {
                  width: 86px;
                }
                b {
                  font-size: 9px;
                }
                a {
                  color: $ff-active-blue;
                  font-size: 9px;
                  text-decoration: none;
                }
              }
            }
            &.hardware-progress {
              li {
                border-bottom: 0 none !important;
                font-size: 9px;
                &:not(:first-child) {
                  span {
                    float: left;
                    width: 50px;
                    padding-right: 5px;
                  }
                }
                .progress {
                  margin: 5px !important;
                  background-color: $header-menu-item-color !important;
                  .progress-bar {
                    background-color: $ff-active-blue !important;
                    width: 70% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#select-offer {
  .modal-dialog {
    width: 480px !important;
    .modal-content {
      padding: 50px 12px 40px !important;
    }
  }
  .arrow-back {
    position: absolute;
    top: 3px;
    .back {
      font-family: 'Arquitecta';
      letter-spacing: 1px;
      text-transform: uppercase;
      text-decoration: none;
      color: $ff-grey-active;
    }
    img {
      @include rotate(180deg);
      width: 10px !important;
    }
    span {
      vertical-align: sub;
    }
  }
}

#booking {
  .modal-content {
    height: 554px;
    min-height: 414px;
    .modal-header {
      border-bottom: 0 none !important;
      h4 {
        font-family: 'Arquitecta';
        font-size: 20px;
        font-weight: bold;
        color: $ff-grey-active;
      }
      button.close {
        position: absolute;
        top: 15px;
        right: 15px;
        font-weight: normal !important;
        opacity: 1 !important;
      }
    }
    .modal-body {
      form {
        h5 {
          margin-top: 0;
        }
        label {
          margin-left: 0 !important;
          margin-bottom: 21px;
        }
        input {
          border-radius: 3px !important;
          @include placeholder($light-gray);
        }
        .confirm {
          background: transparent !important;
          border-color: $public-profile-bg-color !important;
          color: $public-profile-bg-color !important;
          border-radius: 3px;
          width: 110px;
          display: block;
          font-size: 12px;
          margin-bottom: 30px;
          &:focus, &.active {
            border-radius: 6px;
            border-color: $ff-active-blue !important;
            background: $ff-active-blue !important;
            color: $ff-grey-active !important;
          }
          &:hover {
            background: transparent !important;
            border-color: $public-profile-bg-color !important;
            color: $public-profile-bg-color !important;
          }
        }
        ul {
          padding: 0;
          float: left;
          margin-right: 21px;
          li {
            font-size: 12px;
            color: $ff-grey-active;
            a {
              color: $ff-active-blue;
              text-decoration: underline;
            }
          }
        }
        .tel-number {
          .tel {
            width: 100% !important;
            padding-left: 0 !important;
          }
          .phone-dropdown {
            width: 30% !important;
            .btn-group {
              width: 100%;
            }
            button {
              border: 1px solid $ff-grey-active;
              height: 34px;
              padding: 6px;
              z-index: 10;
              margin-top: 2px;
              border-radius: 3px 0 0 3px;
              width: 100% !important;
              &:focus, &:visited, &:active {
                border: 1px solid $ff-grey-active !important;
                box-shadow: none !important;
                outline: none !important;
              }
            }
            .dropdown-menu {
              width: 100%;
              ul {
                li {
                  a {
                    color: $ff-grey-active;
                  }
                }
              }
            }
          }
          .nmb-input {
            width: 70% !important;
            input {
              border-radius: 0 3px 3px 0 !important;
            }
          }
          .btn-group {
            padding-right: 0;
            padding-left: 0;
            div:last-child {
              padding-right: 0;
            }
          }
        }
        .email-button {
          background: transparent !important;
          color: $current-projects-bg-color !important;
          border-color: $current-projects-bg-color !important;
          width: 113px;
          font-size: 12px;
          border-radius: 6px;
          padding: 4px;
          margin-top: 4px;
          &:hover, &:focus {
            background: transparent !important;
            color: $current-projects-bg-color !important;
            border-color: $current-projects-bg-color !important;
          }
        }
      }
    }
  }
}

#booking-conclude {
  .modal-content {
    height: 180px;
    .modal-header {
      border-bottom: 0 none !important;
      h4 {
        font-family: 'Arquitecta';
        font-size: 20px;
        font-weight: bold;
        color: $ff-grey-active;
      }
      button.close {
        position: absolute;
        top: 15px;
        right: 15px;
        font-weight: normal !important;
        opacity: 1 !important;
      }
    }
    .modal-body {
      padding-top: 0;
      a.btn-default {
        width: 110px;
      }
    }
  }
}

#cancel-booking {
  .modal-content {
    height: 140px;
    background-color: $ff-active-blue;
    border-color: $ff-active-blue !important;
    .modal-body {
      h5 {
        font-weight: bold;
        font-size: 12px;
        margin-top: 20px;
        color: $ff-grey-active;
      }
      p {
        color: $ff-grey-active;
        font-size: 12px;
      }
      ul {
        padding: 7px 0 0;
        li {
          button {
            width: 110px;
            border-radius: 6px;
            font-size: 12px;
            &.continue {
              background-color: $white;
              color: $ff-grey-active;
              border-color: $white !important;
              &:hover, &:focus {
                background-color: $white !important;
                color: $ff-grey-active !important;
                border-color: $white !important;
              }
            }
            &.cancel-btn {
              background-color: $ff-grey-active !important;
              color: $white;
              border-color: $ff-grey-active !important;
              &:hover, &:focus {
                background-color: $ff-grey-active !important;
                color: $white !important;
                border-color: $ff-grey-active !important;
              }
            }
          }
        }
      }
    }
  }
}
