.profile {
  .profile-details {
    border: 1px solid $ff-grey-active;
    margin-top: 30px;
    h4 {
      font-family: 'Arquitecta';
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 20px;
      padding: 12px 17px;
      background: $ff-grey-active;
      color: $white;
      margin: 0;
    }
    .add-desc {
      padding: 0;
      margin-top: 18px;
      margin-bottom: 5px;
      font-size: 12px;
    }
    textarea {
      margin: 0 0 15px;
      border: 1px solid $ff-grey-active;
      resize: none;
      font-size: 12px;
      color: $ff-grey-active;
      padding-top: 7px;
      padding-left: 7px;
      padding-right: 7px;
      &:focus {
        outline: none;
      }
    }
    .character-counter {
      padding: 0 0 10px;
    }
    .details-form {
      margin: 24px 0;
    }
    .profile-dropdown {
      margin-bottom: 0;
      margin-top: 3px;
      .control-label {
        margin-bottom: 7px;
      }
      .btn.dropdown-toggle {
        height: 34px;
      }
      .bootstrap-select {
        width: 260px !important;
        &.dropup, &.dropdown {
          div.dropdown-menu {
            width: 260px;
            overflow-x: hidden !important;
          }
        }
      }
    }
    .form-upload {
      .choose-file {
        span {
          background: none;
          color: $black;
          padding: 0;
          border: 0 none;
          vertical-align: middle;
        }
        .upload-img {
          width: 22px;
        }
      }
    }
    form {
      margin-top: 18px;
      margin-bottom: 37px;
      .last-form-group {
        margin-bottom: 5px !important;
      }
    }
    .ff-url {
      font-size: 10px;
      color: $ff-grey-active;
      a {
        text-decoration: none;
        color: $ff-grey-active;
      }
    }
    .profile-avatar {
      margin: 24px auto 0;
    }
  }
  .back {
    color: $black;
    font-family: 'Arquitecta';
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 900;
    margin-top: 28px;
    display: block;
    &:hover {
      text-decoration: none;
      color: $black;
    }
    img {
      width: 20px;
      display: inline-block;
    }
  }
}

.add-project {
  width: auto;
  &:not([disabled]) {
    width: auto;
    background: $ff-active-blue !important;
    color: $white !important;
    border-color: $ff-active-blue !important;
    padding: 6px;
    text-align: center;
    font-size: 13px;
  }
}
