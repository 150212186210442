.current-projects {
  min-height: calc(100vh - 504px);
}

#ff-press-info, .funnel-page {
  min-height: calc(100vh - 348px);
}

.login-btn {
  p {
    margin-right: 25px;
    display: inline-block !important;
  }
  button, a.btn {
    display: inline-block !important;
    vertical-align: baseline;
  }
}

.m-left40 {
  margin-left: -41px !important;
}

.m-left20 {
  margin-left: -22px !important;
}
