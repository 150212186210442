@import '~@angular/material/theming';
// Fix for Checkboxes with long labels https://github.com/angular/material2/issues/8416
.mat-checkbox-layout {
  white-space: normal !important;
}
@include mat-core();
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:700');

$icon-font-path: '../../../node_modules/bootstrap-sass/assets/fonts/bootstrap/';
@import '../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import '../../../node_modules/bootstrap-select/sass/bootstrap-select';
@import 'core/variables';
@import 'core/config';
@import 'core/mixins';
@import 'core/reset';
@import 'core/fonts';
@import 'modules/media/medias';
@import 'pages/_equipment';
@import 'pages/_details';
@import 'pages/_settings';
@import 'pages/_specializations';
@import 'pages/_legal';
@import 'pages/_billing-information';
@import 'pages/quotes-requests';
@import 'pages/login';
@import 'pages/cockpit';
@import 'pages/jobs-overview';
@import 'pages/profile-details';
@import 'pages/public-profile';
@import 'pages/funnel';
@import 'pages/create-quote';
@import 'pages/legal-pages';
@import 'pages/upload-portfolio';
@import 'pages/contact-us';
@import 'pages/about-us';
@import 'pages/landing';
@import 'pages/ff-press-info';
@import 'pages/not-found-page';
@import 'pages/lost';
