.about-pilot {
  .client-info {
    padding: 0;
  }
  padding: 30px;
  .pilot-img {
    background: url('/assets/images/picture2.jpg');
    border-radius: 50%;
    background-size: cover;
    overflow: hidden;
    position: relative;
    height: 70px;
    width: 70px;
  }
  img {
    margin-top: 12px;
  }
  h1 {
    font-size: 25px;
    margin-bottom: 0;
    font-weight: bold;
    font-family: 'Arquitecta';
    color: $ff-grey-active;
    margin-top: 7px;
    letter-spacing: 2px;
  }
  p {
    color: $ff-grey-active;
    font-weight: bold;
    font-size: 12px;
    margin-top: 5px;
  }
  .stars, .certified-pro {
    img {
      margin-top: 0;
    }
  }

  .stars img {
    width: 200px;
  }
}

.collapse-btn {
  width: 100% !important;
  padding: 0 !important;
  border: 0 !important;
}

.current-projects {
  padding-bottom: 15px;
  .work {
    margin-bottom: 20px;
    p {
      margin: 0;
      font-size: 14px;
      &:first-child {
        color: $ff-grey;
        margin-top: 35px;
      }
    }
  }
}

.activities {
  margin: 0;
  &:last-child {
    padding-bottom: 89px;
  }
  h4 {
    font-size: 18px;
    font-family: 'Arquitecta';
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $ff-grey;
    padding: 17px 20px 16px;
    margin: 0;
    word-break: break-word;
  }
  .view-profile {
    max-width: 220px;
    float: right;
    width: 100%;
  }
  .recent {
    max-width: 220px;
    position: relative;
    &.recent-login {
      margin-top: 15px;
    }
    .edit {
      position: absolute;
      bottom: 40px;
      left: 18px;
    }
  }
  .recent-activities {
    height: 245px;
    ul {
      font-size: 12px;
      padding-left: 18px;
      padding-top: 15px;
      &.mutter {
        font-size: 10px !important;
      }
      li {
        &:first-child {
          padding-top: 0 !important;
        }
        padding-top: 15px;
      }
    }
  }
}

.no-left-padding {
  padding-left: 0 !important;
}

.no-right-padding {
  padding-right: 0 !important;
}

.completed {
  padding-right: 30px;
  img {
    width: 65px;
  }
  p {
    a {
      color: $ff-active-blue !important;
    }
  }
}

.projects {
  .table-responsive {
    border: 0 none !important;
    overflow-x: visible;
    .table {
      border-collapse: separate;
      border-spacing: 0 5px;
      thead {
        tr {
          th {
            vertical-align: text-top;
            border: 0 none !important;
            padding: 15px;
            font-size: 12px;
          }
        }
      }
      tbody {
        tr {
          background: white;
          color: $ff-grey;
          td {
            font-size: 12px;
            vertical-align: middle;
            white-space: pre-line;
          }
        }
      }
    }
  }
  .create-btn {
    background-color: $ff-active-blue;
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 3px 15px;
    border: 1px solid $ff-active-blue;
    border-radius: 6px;
    text-decoration: none;
    &:hover, &:visited, &:active {
      background-color: $ff-active-blue;
      color: #fff;
      text-decoration: none;
    }
  }
  .active-btn {
    font-size: 18px;
    opacity: 1 !important;
    color: $white;
    text-decoration: none !important;
    &:hover {
      color: $white;
    }
  }

  .some-news {
    margin: 0;
    padding-top: 20px;
    padding-bottom: 16px;
  }
  ul {
    padding: 0;
    margin-bottom: 0;
    h5 {
      margin-top: 0;
      color: $ff-active-blue;
      font-size: 18px;
    }
    h3 {
      margin-top: 0;
      font-size: 18px;
      color: $ff-grey-active;
      span {
        color: $request-span-color;
      }
    }
    p {
      font-size: 12px;
    }
  }
  .divider {
    margin: 9px 0;
  }
  .ff-allianz {
    padding-left: 0;
    font-size: 12px;
  }
  .open-button {
    padding-right: 0;
    float: right;
    .open {
      background-color: $ff-dark-blue;
      color: $ff-dark-blue;
      font-size: 12px;
      text-align: center;
      margin: 34px 0;
      padding: 6px;
      display: block;
      border: 1px solid $ff-dark-blue;
      border-radius: 6px;
      text-decoration: none;
      &:hover, &:visited, &:active {
        background-color: $ff-dark-blue;
        color: $header-menu-item-color;
        text-decoration: none;
      }
    }
  }
  h4 {
    font-size: 24px;
    font-family: 'Arquitecta';
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $ff-grey;
    padding: 17px 20px 16px;
    margin: 0;
    word-break: break-word;
  }
}

.shadow {
  background: white;
  border-radius: 10px;
  border: 1px solid white;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
}

.view-profile {
  &.view-new {
    margin-top: 15px;
    h4 {
      small {
        color: $ff-active-blue;
        font-size: 12px;
      }
    }
  }
  .recent-activities {
    height: 305px;
    ul {
      padding-top: 20px;
      li {
        padding-top: 5px;
        a {
          color: $header-menu-item-color;
          text-decoration: none;
        }
        .progress {
          margin: 0;
          padding: 0;
          background: $ff-grey-active !important;
          height: 24px !important;
          .progress-bar {
            width: 80%;
          }
        }
        .edit {
          margin: 20px 18px 25px 0;
          padding: 0 !important;
          display: block;
          text-decoration: underline;
          color: $ff-active-blue;
        }
      }
      &.recent-active {
        li {
          &:first-child, &:nth-child(2) {
            color: $light-gray;
          }
        }
      }
    }
  }
}

.error-img {
  width: 20px;
  position: absolute;
  left: -10px;
  margin-top: 40px;
}

.point {
  a {
    cursor: pointer;
  }
}

.settings-collapse {
  transition: none;
  .collapse-content {
    max-height: 290px;
    min-height: 50px;
    overflow-y: auto;
    padding: 20px;
    width: 100%;
  }
  margin-top: 2px;
  width: 200px;
  background-color: $settings-collaps-bg-color;
  position: absolute;
  z-index: 100;
  right: 10%;
  p {
    a {
      color: $ff-active-blue !important;
      text-decoration: underline;
      padding-right: 0;
      font-size: 10px;
      text-transform: none;
    }
    font-size: 9px;
  }
  ul {
    padding-top: 0 !important;
    li {
      margin-bottom: 10px;
      h3 {
        color: $ff-active-blue;
        font-size: 12px;
        margin: 0;
      }
      p {
        color: $white;
        font-size: 9px;
        margin: 0;
      }
      small {
        font-size: 6px;
        color: $white;
      }
      h4 {
        font-size: 10px;
        margin: 0;
        text-align: center;
        color: $white;
      }
    }
  }
}

.no-activities {
  font-size: 14px !important;
  padding-right: 30px;
}
