.quote-packages {
  padding-top: 15px;
  padding-bottom: 15px;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: $ff-grey-active;
  .selected-offer {
    span.avatar {
      width: 40px;
      margin-right: 15px;
      float: left;
      border-radius: 50%;
      background-size: cover;
      overflow: hidden;
      position: relative;
      height: 40px;
    }
    img {
      width: 40px;
      margin-right: 15px;
      float: left;
    }
    ul {
      padding: 0;
      li {
        font-size: 12px;
        line-height: 17px;
        &:first-child {
          color: $ff-grey-active;
        }
        &:last-child:not(.pilot-rating) {
          color: $ff-active-blue;
        }
        img {
          width: 65px;
          margin-right: 0;
        }
        &.title {
          font-family: 'Arquitecta';
          font-size: 20px;
          font-weight: bold;
          color: $ff-grey-active;
        }
      }
    }
    p {
      font-size: 12px;
      margin: 0;
      &.pilot-comments {
        margin-top: 15px;
      }
    }
    button:not(.save-button) {
      font-size: 14px;
      color: $ff-active-blue;
      font-weight: bold;
      width: 144px;
      font-family: 'Arquitecta';
      border-color: $ff-active-blue;
      border-radius: 3px;
      background: $transparent !important;
      &:focus, &:hover {
        border-color: $ff-active-blue !important;
        background: $transparent !important;
        color: $ff-active-blue !important;
      }
    }
  }
  .details-package {
    padding-right: 7px;
    margin-bottom: 20px;
    .quotes-details {
      border-radius: 3px;
      padding: 0;
      border: 1px solid $ff-grey-active;
      h1 {
        margin: 0;
        font-size: 24px;
        color: $header-menu-item-color;
        font-weight: bold;
      }
      h2 {
        font-size: 20px;
        margin: 7px 0;
        font-weight: bold;
        color: $header-menu-item-color;
        .caret {
          @include rotate(180deg);
          border-top: 12px dashed !important;
          border-right: 7px solid transparent !important;
          border-left: 7px solid transparent !important;
        }
      }
      ul {
        margin-top: 4px;
        margin-bottom: 0;
        background: $site-map-bg-color;
        padding: 2px;
        li {
          font-size: 12px;
          color: $ff-grey-active;
          &:not(:first-child, :last-child) {
            border-bottom: 1px solid $ff-grey-active;
          }
          span {
            &:last-child {
              width: 86px;
            }
            b {
              font-size: 9px;
            }
            a {
              color: $ff-active-blue;
              font-size: 9px;
              text-decoration: none;
            }
          }
        }
        &.hardware-progress {
          li {
            border-bottom: 0 none !important;
            font-size: 9px;
            &:not(:first-child) {
              span {
                float: left;
                width: 50px;
                padding-right: 5px;
              }
            }
            .progress {
              margin: 5px !important;
              background-color: $header-menu-item-color !important;
              .progress-bar {
                background-color: $ff-active-blue !important;
              }
            }
          }
        }
      }
    }
  }
}
